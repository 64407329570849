import '../styles/package-option-item.list.scss';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash'; // Import debounce
import { PackageOption } from '../defs/order-model';
import { removePackageOption, updatePackageOption } from '../api/packages.api';
import { Input } from './Input';
import { Checkbox } from './Checkbox';

export const PackageOptionListItem = ({
    option,
    update,
}: {
    option: any;
    update: any;
}) => {
    const [deleting, setDeleting] = useState(false);
    const [saving, setSaving] = useState(false);
    const [opt, setOpt] = useState(option);

    // Debounce the onChange function
    const debouncedOnChange = debounce((opt: PackageOption) => {
        setSaving(true);
        updatePackageOption(-1, opt as any).then(() => setSaving(false));
    }, 300); // 300 milliseconds debounce time

    // Function for saving the option
    const onChange = (newOpt: PackageOption) => {
        debouncedOnChange(newOpt); // Use the debounced function
        setOpt(newOpt);
    };

    // Delete the package option
    const remove = async () => {
        setDeleting(true);

        try {
            await removePackageOption(-1, opt);
            update();
        } catch (err) {
        }

        setDeleting(false);
    };

    return (
        <div className="package-option-list-item">
            <div className="details">
                <Input
                    label="Name"
                    value={opt.name}
                    onChange={(v: any) => onChange({ ...opt, name: v })}
                />

                {/* Check component */}
                <Checkbox
                    label="Checked"
                    value={opt.checked}
                    onChange={(v) => onChange({ ...opt, checked: v })}
                />
            </div>

            {saving && <div className="saving">Saving...</div>}

            <div className="remove" onClick={remove}>
                <FontAwesomeIcon icon={faTrash} />
            </div>
        </div>
    );
};
