import { Customer } from "../defs/customer-model";
import { client } from "./api.config"

export const fetchCustomer = async (id: string | number): Promise<Customer> => {
    const res = await client().get(`/customer/${id}`)
    return res.data;
}

export const getCustomers = async (query: string) => {
    const res = await client().get('/customer', { params: { query } });
    return res.data;
}

// Create a new customer
export const createCustomer = async (data: any) => {
    const res = await client().post(`/customer`, data);
    return res.data;
}


// Update customer display picture
export const updateCustomerDisplayPicture = async (id: number, form: FormData): Promise<{ dp: string }> => {
    const res = await client().post(`/customer/${id}/dp`, form);
    return res.data;
}

// Update a customer
export const updateCustomer = async (customer: Customer): Promise<Customer> => {
    const res = await client().put(`/customer/${customer.id}`, customer)
    return res.data;
}


// Delete a Customer
export const removeCustomer = async (id: number) => {
    const res = await client().delete(`/customer/${id}`);
    return res.data;
}

// Get all the customers for a specific agency
export const getAgencyCustomers = async (agency_id: number) => {
    const res = await client().get(`/agency/${agency_id}/customers`);
    return res.data;
}

// Get all the teams for a customer
export const getCustomerTeams = async (customer_id: number) => {
    const res = await client().get(`/customer/${customer_id}/teams`);
    return res.data;
}

// Get packages for a customer id
export const getPackagesForCustomer = async (customer_id: number) => {
    const res = await client().get(`/customer/${customer_id}/packages`);
    return res.data;
}