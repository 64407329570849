
import "../styles/upload-image-mini.comp.scss";
import axios from "axios";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, useRef, useState } from "react";
import { CircleLoader, DotLoader } from "react-spinners";
import { client } from "../api/api.config";
import { accent_color } from "../defs/color.def";

interface Props {
    onChange: (image: string, error?: string) => Promise<void>;
    onRemove?: (image: string) => void;
    endpoint_url: string;
}

/**
 * This image uploader is generic and will dump images on the server in a media section.
 * @param props 
 * @returns 
 */
export const UploadImageMiniButton = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const ref = useRef<HTMLInputElement>(null!);
    const [vis, setVis] = useState(false);


    const handle_click = () => {
        if (ref) ref.current?.click();
    }

    // TODO: When time permits make this a multiple image upload.
    const simulate_click = async (e: ChangeEvent<HTMLInputElement>) => {
        const file = e?.target?.files?.[0];
        if (!file) return;

        setLoading(true);

        // Try to upload the image
        try {
            const form = new FormData();
            form.append("image", file);

            // Make API call here
            // const res = await client().post(`/order/am/media/create`, form);
            const res = await client().post(props.endpoint_url, form);

            if (res.status === 201) {
                await props.onChange(res.data?.url);
            }
        } catch (err) {
            console.error("Failed to upload the image.", err);
        }

        setLoading(false);
    }

    return (
        <>
            <div className="image-mini-wrapper" onClick={() => handle_click()}>

                {!loading && <div className="add-icon">
                    <FontAwesomeIcon icon={faPlus} />
                </div>}

                {loading &&
                    <div className="image-loading">
                        <DotLoader size={25} color={accent_color} />
                    </div>
                }

                <input
                    className="hidden-button"
                    type="file"
                    accept="image/*"
                    onChange={simulate_click}
                    multiple={false}
                    ref={ref}
                />
            </div>
        </>

    )

}