import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import '../styles/view-header-menu.comp.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropdownItem, DropdownMenu } from './DropdownMenu';

interface Props {
    icon?: IconDefinition
    label: string;
    options: DropdownItem[];
}

export const ViewHeaderMenu = (props: Props) => {
    return (
        <div className="view-header-menu">
            <div className="label">
                {props.icon && <FontAwesomeIcon icon={props.icon} />}
                {props.label}</div>
            <DropdownMenu items={props.options} />
        </div>
    );
};
