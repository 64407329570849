import "../../styles/view-all-teams-view.scss";
import { useEffect, useState } from "react"
import { Team } from "../../defs/team-model";
import { View } from "../../layout/View";
import { ViewHeader } from "../../comps/ViewHeader";
import { Body } from "../../layout/BodyLayout";
import { Serachbar } from "../../comps/Searchbar";
import { List } from "../../layout/ListLayout";
import { getTeams } from "../../api/team.api";
import { useNavigate } from "react-router-dom";


// TODO: If time permist, we need to add in a create team view.
export const ViewAllTeamsView = () => {
    const navigate = useNavigate();
    const [teams, setTeams] = useState<Team[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [query, setQuery] = useState("");

    // Run the query
    const runQuery = async (q?: string) => {
        setLoading(true);

        try {
            const teams = await getTeams(q || query);
            setTeams(teams);
        } catch (err) {
            setError("Failed to query the teams.");
        }

        setLoading(false);
    }

    useEffect(() => {
        runQuery();
    }, []);

    return (
        <View>
            <ViewHeader title="View Teams" back />

            <Body>
                <Serachbar query={query} setQuery={setQuery} onSearch={runQuery} loading={loading} />

                <List>
                    {teams?.map((team: Team, i: number) => (
                        <div className="team-list-item" onClick={() => navigate(`/staff/teams/${team.id}`)}>
                            <div className="team-list-item-name">{team.name}</div>

                            <div className="details">
                                {team?.lead_customer && (
                                    <div className="team-leading-customer">
                                        <label>Lead</label>
                                        <div className="value">
                                            {team?.lead_customer?.first_name} {team.lead_customer?.last_name}
                                        </div>
                                    </div>)}
                                <div className="agency">
                                    <label>Agency</label>
                                    <div className="value">
                                        {team?.agency?.name}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </List>
            </Body>
        </View>
    )

}