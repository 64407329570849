import "../styles/select-agency.comp.scss";
import { Agency } from "../defs/agency-model";
import { useEffect, useState } from "react";
import { getAgenciesViaOrg } from "../api/org.api";
import { InlineLoader } from "./InlineLoader";


interface Props {
    corp?: number;
    value: number | null;
    onChange: (agency_id: number | null) => void;
}


export const SelectAgency = (props: Props) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [agencies, setAgencies] = useState<Agency[]>([]);

    // Load the agencies from the API
    const load = async () => {
        if (!props.corp) return;
        setLoading(true);

        try {
            const agencies = await getAgenciesViaOrg(props.corp);
            setAgencies(agencies);
        } catch (err) {
            setError("Failed to load the agencies.");
        }

        setLoading(false);
    }

    useEffect(() => {
        if (props.corp) {
            load();
        }
    }, [props.corp])

    return (
        <div className="agency-list-select">
            <label>Select Agency</label>

            {!props.corp && <p className="error">Please select a corporation first.</p>}

            {loading && <div className="loader-con">
                <InlineLoader />
            </div>}

            {props.corp && agencies?.length <= 0 && <p className="error">No agencies found.</p>}

            <div className="agency-list">
                {agencies.map(agency =>
                (
                    <div className="agency-list-item" id={props.value && (props.value === agency.id && "selected") || ""}
                        onClick={() => props.onChange(agency.id)}
                    >
                        <div className="agency-list-item-con">
                            <div className="agency-list-item-name">{agency.name}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div >
    )

}