import "../../styles/view-team-member-view.scss";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useRouteError } from "react-router-dom"
import { Team, TeamMember } from "../../defs/team-model";
import { getTeam } from "../../api/order.api";
import { View } from "../../layout/View";
import { ViewHeader } from "../../comps/ViewHeader";
import { Body } from "../../layout/BodyLayout";
import { FormLayout } from "../../layout/FormLayout";
import { Input } from "../../comps/Input";
import { connectTeamToClickup, deleteTeam, removeTeamMemberFromTeam, setTeamLeadCustomer, updateTeam } from "../../api/team.api";
import { Text } from "../../comps/Text";
import { Button } from "../../comps/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNetworkWired, faStar, faTimes, faTools, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useModals } from "../../hooks/modal-provider";
import { AddTeamMemberModal } from "../../comps/AddTeamMember";
import { ViewHeaderMenu } from "../../comps/ViewHeaderMenu";

export const ViewTeamView = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const modal = useModals();
    const [team, setTeam] = useState<Team>(null!);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [updating, setUpdating] = useState(false);

    // Local update
    const local_update = (key: keyof Team, value: any) => {
        setTeam({ ...team, [key]: value });
    }

    // Update
    const update = async () => {
        if (!id || !team) return;

        setUpdating(true);

        try {
            const res = await updateTeam(+id, {
                name: team.name,
                lead_customer_id: team.lead_customer.id,
                clickup_id: team?.clickup_id || "",
            });
            load();
        } catch (err) {
            setError("Failed to update the team.");
        }

        setUpdating(false);
    }

    // Get the team
    const load = async () => {
        if (!id) return;
        setLoading(true);

        try {
            const res = await getTeam(+id);
            setTeam(res);
        } catch (err) {
            setError("Failed to load the team!");
        }

        setLoading(false);
    }

    const setLead = async (member: TeamMember) => {
        if (!member.customer.id || !id) return;
        setUpdating(true);
        setError("");

        try {
            await setTeamLeadCustomer(+id, member.customer.id);
            local_update("lead_customer", member.customer);
        } catch (err) {
            setError("Failed to set the lead...");
        }

        setUpdating(false);
    }

    // Remove a member
    const removeMember = async (member: TeamMember) => {
        if (!member.customer.id || !id) return;

        setUpdating(true);
        setError("");

        try {
            await removeTeamMemberFromTeam(+id, member.customer.id);
            local_update("members", team?.members?.filter((m) => m.customer.id !== member.customer.id));
        } catch (err) {
            setError("Failed to remove the member...");
        }

        setUpdating(false);
    }


    // Open add team member modal
    const openAddTeamMemberModal = () => {
        modal.create({
            id: "add-team-member",
            title: "Add Team Member",
            content: <AddTeamMemberModal team={team} onAdd={() => load()} />,
        })
    }

    // Remove the team
    const remove = async () => {
        if (!id) return;
        setLoading(true);

        try {
            await deleteTeam(+id);
            navigate("/staff/teams");
        } catch (err) {
            setError("Failed to remove the team...");
        }

        setLoading(false);
    }

    // Create a clickup list for this team
    const create_clickup_list = async () => {
        if (!id) return;
        setUpdating(true);
        setError("");

        try {
            await connectTeamToClickup(+id);
        } catch (err) {
            setError("Failed to create the list...");
        }

        setUpdating(false);
    }


    useEffect(() => {
        if (id) load();
    }, [id]);


    return (
        <View>
            <ViewHeader title="View Team" back menu={
                <ViewHeaderMenu label="Actions" icon={faTools} options={[
                    {
                        label: "Create in Clickup",
                        icon: faNetworkWired,
                        onClick: () => create_clickup_list()
                    },
                    {
                        label: "Remove Customer",
                        icon: faTrashAlt,
                        onClick: () => remove()
                    }
                ]} />
            } />

            <Body>
                <FormLayout>
                    <Text label="Agency" value={team?.agency?.name} />
                    <Text label="Leading Agent" value={`${team?.lead_customer?.first_name} ${team?.lead_customer?.last_name}`} />

                    <Input label="Name" value={team?.name} onChange={v => local_update("name", v)} />
                    <Input label="Clickup Id" value={team?.clickup_id || ""} onChange={v => local_update("clickup_id", v)} />

                    {/* List out the members */}
                    <div className="members-header">
                        <label>Team Members</label>
                        <div className="add-member" onClick={() => openAddTeamMemberModal()}>
                            Add Member
                        </div>
                    </div>

                    <div className='members'>
                        {team?.members?.map((member: TeamMember, i) => (
                            <TeamMemberListItem key={i} member={member} setLead={() => setLead(member)} remove={() => removeMember(member)} />
                        ))}
                    </div>


                    <Button onClick={update} loading={updating || loading}>
                        Update Team
                    </Button>
                </FormLayout>
            </Body>

        </View >
    )
}

export interface TeamMemberListItemProps {
    member: TeamMember;
    remove?: () => void;
    setLead?: () => void;
}

export const TeamMemberListItem = (props: TeamMemberListItemProps) => {
    return (
        <div className="team-member-list-item">
            <div className="left">
                <div className="dp" style={{
                    backgroundImage: `url(${props.member.customer.dp || ''})`
                }} />
                <div className="name">
                    {props.member.customer.first_name} {props.member.customer.last_name}
                </div>
            </div>
            {
                props.remove && props.setLead && (
                    <div className="right-opts">
                        <div className="set-lead" onClick={props.setLead}>
                            <FontAwesomeIcon icon={faStar} />
                        </div>
                        <div className="remove" onClick={props.remove}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </div>
                )
            }
        </div >
    )
}