import "../../styles/view-all-staff-view.scss";
import { useEffect, useState } from "react";
import { queryStaffMembers } from "../../api/staff.api";
import { View } from "../../layout/View";
import { ViewHeader } from "../../comps/ViewHeader";
import { Serachbar } from "../../comps/Searchbar";
import { Body } from "../../layout/BodyLayout";
import { List } from "../../layout/ListLayout";
import { Staff } from "../../defs/staff-model";
import { ViewHeaderMenu } from "../../comps/ViewHeaderMenu";
import { faCirclePlus, faTools } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export const ViewAllStaffView = () => {
    const navigate = useNavigate();
    const [query, setQuery] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [staff, setStaff] = useState<Staff[]>([]);

    // Load the Staff Members
    const load = async () => {
        try {
            const staff = await queryStaffMembers(query);
            setStaff(staff);
        } catch (err) {
            console.error("Failed to load the staff members.");
            setError("Failed to retrieve the staff members");
        }
    }

    useEffect(() => {
        load();
    }, []);

    return (
        <View>
            <ViewHeader title="View Staff Members" back
                menu={
                    <ViewHeaderMenu label="Actions" icon={faTools} options={[
                        {
                            label: "Create Staff Member",
                            icon: faCirclePlus,
                            onClick: () => navigate("/staff/staff/create")
                        }
                    ]} />
                }
            />

            <Body>
                <Serachbar query={query} setQuery={setQuery} onSearch={load} loading={loading} />

                <List>
                    {staff?.map((staff, index) => (
                        <div className="staff-member-list-item" key={index} onClick={() => navigate(`/staff/staff/${staff.id}`)}>
                            <div
                                className="dp"
                                style={{
                                    backgroundImage: `url('${staff?.dp
                                        ? staff.dp
                                        : `https://ui-avatars.com/api/?name=${staff?.first_name}+${staff?.last_name}&background=404e3d&color=fff&size=128`
                                        }')`,
                                }}
                            />

                            <div className="staff-details">
                                <div className="staff-member__name">{`${staff.first_name} ${staff.last_name}`}</div>
                                <div className="staff-member__email">{staff.email}</div>
                            </div>
                        </div>
                    ))}
                </List>
            </Body>
        </View>
    )
}
