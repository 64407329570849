import "../styles/select-org-comp.scss";
import { useEffect, useState } from "react";
import { InlineLoader } from "./InlineLoader";
import { Corporation } from "../defs/corporation-model";
import { loadOrgs } from "../api/org.api";

interface Props {
    value: number | null;
    onChange: (corp_id: number | null) => void;
}

export const SelectOrg = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [orgs, setOrgs] = useState<Corporation[]>([]);

    // Load the corps
    const load = async () => {
        setLoading(true);

        try {
            const corps = await loadOrgs();
            setOrgs(corps);
        } catch (err) {
            setError("Failed to load the corporations.");
        }

        setLoading(false);
    }

    useEffect(() => {
        load();
    }, []);

    return (
        <div className="org-list-select">
            <label>Select Corporation</label>

            {loading && <div className="loader-con">
                <InlineLoader />
            </div>}

            {orgs?.length <= 0 && <p className="error">No corporations found.</p>}

            <div className="org-list">
                {orgs.map(org =>
                (
                    <div className="org-list-item" id={props.value && (props.value === org.id && "selected") || ""}
                        onClick={() => props.onChange(org.id)}
                    >
                        <div className="org-list-item-con">
                            <div className="org-list-item-name">{org.name}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div >
    );
}