import "../styles/logo.comp.scss";

const DEF_WHITE_LOGO = "/img/logo-text.svg"
const DEF_LOGO = "/img/logo-text-black.svg"

interface Props {
    alt?: boolean;
}

export const Logo = (props: Props) => {
    return (
        <div className="logo-wrapper" onClick={() => window.location.href = "https://23rdst.com.au"}>
            <img src={props.alt ? DEF_WHITE_LOGO : DEF_LOGO} alt="23rd Street Marketing" />
        </div >
    )
}