import { client } from "./api.config"

export const getPacakges = async () => {
    const res = await client().get('/package');
    return res.data;
}

export const getPackage = async (id: string | number) => {
    const res = await client().get(`/package/${id}`);
    return res.data;
}

// Create a new package
export const createPackage = async (data: any) => {
    const res = await client().post('/package', data);
    return res.data;
}

export const updatePackage = async (id: string | number, data: any) => {
    delete data.package_options;
    const res = await client().put(`/package/${id}`, data);
    return res.data;
}

// Remove a package
export const removePackage = async (id: string | number) => {
    const res = await client().delete(`/package/${id}`);
    return res.data;
}

// Update package option
export const updatePackageOption = async (id: string | number, data: any) => {
    const res = await client().put(`/package/${id}/option/${data.id}`, data);
    return res.data;
}

// Remove package option
export const removePackageOption = async (id: string | number, data: any) => {
    const res = await client().delete(`/package/${id}/option/${data.id}`, data);
    return res.data;
}

// Add package option
export const addPackageOption = async (id: string | number, data: any) => {
    const res = await client().post(`/package/${id}/option`, data);
    return res.data;
}

// Get the clickup package options
export const getClickupPackageOptions = async (id: string | number) => {
    const res = await client().get(`/package/${id}/clickup-options`);
    return res.data;
}