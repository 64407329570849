import React from 'react';
import ReactDOM from 'react-dom/client';
import { RootRouter } from './routing/root-router';
import { AlertProvider } from './hooks/alert-provider';
import { ModalProvider } from './hooks/modal-provider';
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://c79bc1201556c003cdf60844dbfe6baf@o4506035971883008.ingest.sentry.io/4506035973259264",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <AlertProvider>
            <ModalProvider>
                <RootRouter />
            </ModalProvider>
        </AlertProvider>
    </React.StrictMode>
);