import { useEffect, useState } from "react"
import { ViewHeader } from "../../comps/ViewHeader"
import { View } from "../../layout/View"
import { List } from "../../layout/ListLayout";
import { createPackage, getPacakges } from "../../api/packages.api";
import { PackageListItem } from "../../comps/PackageListItem";
import { Package } from "../../defs/order-model";
import { ViewHeaderMenu } from "../../comps/ViewHeaderMenu";
import { faPlusCircle, faTools } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Body } from "../../layout/BodyLayout";


export const ViewPackagesView = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [packages, setPackages] = useState<Package[]>([]);
    const [error, setError] = useState("");

    const load = async () => {
        try {
            const packages = await getPacakges();
            setPackages(packages);
        } catch (err) {
            setError("Failed to load packages");
        }
    }

    const create = async () => {
        setLoading(true);

        try {
            await createPackage({
                name: "Test Package",
                desc: "Test Package Description",
                price: 100000,
                min_days: 2,
                max_days: 21,
                impressions: 1000,
                list_order: 0,
            })

            await load();
        } catch (err) {
            setError("Faield to create package.");
        }
    }

    useEffect(() => {
        load();
    }, []);

    return (
        <View>
            <ViewHeader title="View Packages" back
                menu={
                    <ViewHeaderMenu
                        label="Actions"
                        icon={faTools}
                        options={[
                            {
                                label: 'Create Package',
                                icon: faPlusCircle,
                                onClick: () => create()
                            },
                        ]}
                    />}
            />

            <Body>
                <List>
                    {packages.map((pack: Package) => (
                        <PackageListItem key={pack.id} pack={pack} />
                    ))}
                </List>
            </Body>
        </View>
    )
}