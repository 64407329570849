import "../../styles/remax-listing.remax-view.scss";
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { address_to_string, download_remax_listing_images, fetch_listing } from "../../api/remax.api";
import { View } from "../../layout/View";
import { ViewHeader } from "../../comps/ViewHeader";
import { Body } from "../../layout/BodyLayout";
import { Row } from "../../layout/RowLayout";
import { Container } from "../../layout/ContainerLayout";
import { FormLayout } from "../../layout/FormLayout";
import { Text } from "../../comps/Text"
import { Button } from "../../comps/Button";
import { add_commas } from "../../utils/numbers";

export const ViewRemaxListingView = () => {
    // Get the id from the URL
    const { id } = useParams<{ id: string }>();

    const [listing, setListing] = useState<any>(null!);
    const [loading, setLoading] = useState(true);
    const [downloading, setDownloading] = useState(false);
    const [error, setError] = useState("");

    // Get the remax listing
    const get_listing = async () => {
        if (!id) return;
        setLoading(true);

        try {
            const listing = await fetch_listing(id);
            if (listing) setListing(listing as any);
        } catch (err) {
            console.error("Failed to get the listing: ", err);
            setError("Failed to get the listing.")
        }

        setLoading(false);
    }

    const download = async () => {
        if (!listing || downloading) return;

        setDownloading(true);

        try {
            const download = await download_remax_listing_images(String(listing.listingID));
            const downloadUrl = window.URL.createObjectURL(download);

            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = `${address_to_string(listing)}-images.zip`
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);

            // const urls = listing?.images?.map((image: any) => image?.url);
            // await download_images(urls, address_to_string(listing));
        } catch (err) {
            console.error("Failed to download the images: ", err);
            setError("Failed to download the images.");
        }

        setDownloading(false);
    }


    useEffect(() => {
        if (id) get_listing();
    }, [id])

    return (
        <View>
            <ViewHeader title="Remax Listing" back />

            {error && <div className="error">{error}</div>}

            {/* Details */}
            <Body>
                <Row>
                    <Container>
                        <FormLayout>
                            <Text label="Address" value={listing ? address_to_string(listing) : ""} />
                            <Text label="Type" value={listing?.type} />
                            <Text label="Status" value={listing?.status} />
                            <Text label="Headline" value={listing?.headline} />
                            <Text label="Price" value={`$${add_commas(listing?.price?.price)}` || ""} />
                        </FormLayout>
                    </Container>
                </Row>

                <Row>
                    <Container>
                        <Text label="Description" value={listing?.description} />
                    </Container>
                </Row>

                <Row>
                    <Container>
                        <Button onClick={() => download()} loading={downloading}>Download Images</Button>
                    </Container>
                </Row>

                <Row>
                    <Container>
                        <div className="gallery-grid">
                            {listing?.images?.map((img: any) => (
                                <img src={img?.url} />
                            ))}
                        </div>
                    </Container>
                </Row>
            </Body>
        </View>
    );
}