import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { LoginView } from "../views/auth/LoginView";
import { StaffLayout } from "../layout/StaffLayout";
import { StaffHomeView } from "../views/home/StaffHomeView";
import { ViewPackagesView } from "../views/packages/ViewPackagesView";
import { EditPackageView } from "../views/packages/EditPackageView";
import { ViewAllOrders } from "../views/order/ViewAllOrders";
import { ViewAllCustomers } from "../views/clients/ViewAllClients";
import { CreateOrgView } from "../views/clients/CreateOrgView";
import { CreateAgencyView } from "../views/clients/CreateAgencyView";
import { CreateCustomerView } from "../views/clients/CreateCustomerView";
import { ViewCustomerView } from "../views/clients/ViewCustomerView";
import { ViewVpaOrder } from "../views/order/ViewVpaOrder";
import { ViewAmOrder } from "../views/order/ViewAmOrder";
import { ViewAllRemaxListingsView } from "../views/remax/ViewAllRemaxListings";
import { ViewRemaxListingView } from "../views/remax/ViewRemaxListing";
import { ViewAllStaffView } from "../views/staff/ViewAllStaff";
import { CreateStaffView } from "../views/staff/CreateStaffView";
import { ViewStaffView } from "../views/staff/ViewStaffView";
import { ViewAllOrganisations } from "../views/orgs/ViewAllOrganisations";
import { ViewOrganisation } from "../views/orgs/ViewOrganisation";
import { ViewAllAgencies } from "../views/agencies/ViewAllAgencies";
import { ViewAgencies } from "../views/agencies/ViewAgencies";
import { CreateVpaOrder } from "../views/order/CreateVpaOrder";
import { CreateAmOrderView } from "../views/order/CreateAmOrder";
import { ViewAllTeamsView } from "../views/teams/ViewAllTeamsVIew";
import { ViewTeamView } from "../views/teams/ViewTeamView";

export const RootRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="login" element={<LoginView />} />

                <Route path="staff" element={<StaffLayout />}>
                    <Route path="home" element={<StaffHomeView />} />

                    <Route path="package" element={<ViewPackagesView />} />
                    <Route path="package/:id" element={<EditPackageView />} />

                    <Route path="orders" element={<ViewAllOrders />} />
                    <Route path="orders/create-vpa" element={<CreateVpaOrder />} />
                    <Route path="orders/create-am" element={<CreateAmOrderView />} />
                    <Route path="orders/vpa/:id" element={<ViewVpaOrder />} />
                    <Route path="orders/am/:id" element={<ViewAmOrder />} />

                    <Route path="clients" element={<ViewAllCustomers />} />
                    <Route path="clients/:id" element={<ViewCustomerView />} />
                    <Route path="clients/create-customer" element={<CreateCustomerView />} />

                    <Route path="teams" element={<ViewAllTeamsView />} />
                    <Route path="teams/:id" element={<ViewTeamView />} />

                    <Route path="staff" element={<ViewAllStaffView />} />
                    <Route path='staff/create' element={<CreateStaffView />} />
                    <Route path="staff/:id" element={<ViewStaffView />} />

                    <Route path='org' element={<ViewAllOrganisations />} />
                    <Route path="org/create" element={<CreateOrgView />} />
                    <Route path="org/:id" element={<ViewOrganisation />} />

                    <Route path="agencies" element={<ViewAllAgencies />} />
                    <Route path="agencies/create" element={<CreateAgencyView />} />
                    <Route path="agencies/:id" element={<ViewAgencies />} />

                    <Route path="remax" element={<ViewAllRemaxListingsView />} />
                    <Route path="remax/listings/:id" element={<ViewRemaxListingView />} />

                    <Route path="*" element={<Navigate to="home" />} />
                </Route>

                <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
        </BrowserRouter>
    );
};