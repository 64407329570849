import '../styles/order-item.list.scss';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Order, OrderType } from '../defs/order-model';
import { useUserStore } from '../stores/user-store';
import { convert_type } from '../api/order.api';
import { Text } from "./Text"

export const OrderListItem = ({ order, limited }: { order: Order, limited?: boolean }) => {
    const user_auth = useUserStore((state: any) => state.user);
    const navigate = useNavigate();

    const handle_text_color = (color?: string) => {
        if (!color) return {
            color: '#232323',
        }
        return {
            color: color,
        }
    }

    if (order?.order_type === OrderType.AM) {
        return (
            <div className="order-list-item" onClick={() => navigate(`/staff/orders/am/${order.id}`)}>
                <div className="order-type">
                    {convert_type(order?.am_order?.type)} Agent Marketing Campaign
                    {order?.am_order?.type === "SOLD" && (
                        <div className="address">
                            {order?.am_order?.address}
                        </div>
                    )}
                </div>
                <div className="details">
                    {order?.team?.lead_customer && (
                        <div className="row">
                            <Text label="Agent" value={`${order?.team?.lead_customer?.first_name} ${order?.team?.lead_customer?.last_name}`} />
                        </div>
                    )}
                    {order?.am_order?.budget && (<div className="row">
                        <Text label="Budget" value={`$${order?.am_order?.budget / 100}`} />
                    </div>)}
                    <div className="row">
                        <Text label="Status" value={order.status} />
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div
                className="order-list-item"
                onClick={() => navigate(`/staff/orders/vpa/${order.id}`)}
                tabIndex={0}
            >
                <div className="address">{order?.vpa_order?.address}</div>
                <div className="details">
                    {order?.team?.lead_customer && (
                        <div className="row">
                            <Text label="Agent" value={`${order?.team?.lead_customer?.first_name} ${order?.team?.lead_customer?.last_name}`} />
                        </div>
                    )}

                    {!limited && (<div className="row">
                        <Text
                            label="Start"
                            value={format(new Date(order?.vpa_order?.start_date), 'dd MMM yyyy')}
                        />
                        <Text
                            label="End"
                            value={format(new Date(order?.vpa_order?.start_date), 'dd MMM yyyy')}
                        />
                    </div>)}
                    <div className="row">
                        <Text label="Package" value={order?.vpa_order?.pack?.name} />
                        <Text label="Status" value={order.status} />
                    </div>
                </div>
            </div>
        );
    }
};
