import "../../styles/view-am-order.view.scss";
import { useEffect, useState } from "react"
import { Order, get_am_type_from_select, get_selected_am_type, select_am_types, select_order_status } from "../../defs/order-model";
import { useParams } from "react-router-dom";
import { addAgentMarketinMedia, convert_type, downloadAmMediaImages, fetchOrder, getTeam, removeAgentMarketingMedia, updateAmOrder, updateVpaOrder } from "../../api/order.api";
import { View } from "../../layout/View";
import { ViewHeader } from "../../comps/ViewHeader";
import { Body } from "../../layout/BodyLayout";
import { Text } from "../../comps/Text";
import { format } from "date-fns";
import { Select } from "../../comps/Select";
import { Button } from "../../comps/Button";
import { FormLayout } from "../../layout/FormLayout";
import { Input } from "../../comps/Input";
import { Team } from "../../defs/team-model";
import { TwoColumn } from "../../layout/TwoColumn";
import { SlideshowHeader } from "../../comps/SlideShowHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNetworkWired, faTimes, faTools, faTrash } from "@fortawesome/free-solid-svg-icons";
import { set } from "lodash";
import { DateInput } from "../../comps/DateInput";
import { Textarea } from "../../comps/TextArea";
import { CurrencyInputWrapped } from "../../comps/CurrencyInputWrapper";
import { UploadImageMiniButton } from "../../comps/UploadMiniImageButton";
import { ViewHeaderMenu } from "../../comps/ViewHeaderMenu";


export const ViewAmOrder = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [order, setOrder] = useState<Order>(null!);
    const [error, setError] = useState<string>("");
    const [downloading, setDownloading] = useState(false);

    const [team_loading, setTeamLoading] = useState(false);
    const [team, setTeam] = useState<Team>(null!);

    // Load the team this belongs too
    const load_team = async () => {
        if (!order) return;
        setTeamLoading(true);
        try {
            const team = await getTeam(order.team.id);
            setTeam(team)
        } catch (err) {
            setError("Failed to load the team information.");
        }
        setTeamLoading(false);
    }

    // Local update
    const localUpdaate = (field: string, value: any) => {
        setOrder({ ...order, [field]: value });
    }

    // Update Am update local
    const updateAmOrderLocal = (field: string, value: any) => {
        const new_order = { ...order };
        setOrder({
            ...new_order,
            am_order: {
                ...new_order.am_order,
                [field]: value
            }
        });
    }

    // Save
    const save = async () => {
        if (!id) return;
        setUpdating(true);
        setError("");

        try {
            const res = await updateAmOrder(id, {
                notes: order?.am_order?.notes,
                type: order?.am_order?.type,
                address: order?.am_order?.address,
                date: order?.am_order?.date,
                budget: order?.am_order?.budget * 100,
                clickup_id: order?.clickup_id || "",
                status: order?.status,
                facebook_ad_id: order?.facebook_ad_id,
            });
            load();
        } catch (err) {
            setError("Failed to update the order.");
        }

        setUpdating(false);
    }

    // Load
    const load = async () => {
        if (!id) return;

        setLoading(true);
        setError("");

        try {
            const temp = await fetchOrder(id);
            setOrder({
                ...temp,
                am_order: {
                    ...temp.am_order,
                    date: new Date(temp.am_order.date),
                    budget: temp.am_order.budget / 100
                }
            });
        } catch (err) {
            setError("Failed to load the order.");
        }

        setLoading(false);
    }

    // Handle uploading a new image
    const handle_image_upload = async (url: string) => {
        if (!order) return;

        setUpdating(true);

        try {
            const res = await addAgentMarketinMedia(+order.id, url);
            load();
        } catch (err) {
            setError("Failed to set the agent marketing image.");
        }

        setUpdating(false);
    }


    // handle removing an agent marketing iamge
    const handle_remove_image = async (id: number) => {
        if (!order) return;
        setUpdating(true);

        try {
            const res = await removeAgentMarketingMedia(+order.id, id);
            load();
        } catch (err) {
            setError("Failed to set the agent marketing image.");
        }

        setUpdating(false);
    }

    // Download the images 
    const download_images = async () => {
        if (!order || loading || !order?.am_order?.token) return;

        setDownloading(true);

        try {
            const res = await downloadAmMediaImages(order.am_order?.token);
            const downloadUrl = window.URL.createObjectURL(res);

            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = `${id}-order-images.zip`
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);
        } catch (err) {
            setError("Failed to download the images.");
        }

        setDownloading(false);
    }

    useEffect(() => {
        if (id) load();
    }, [id]);

    useEffect(() => {
        if (order) load_team();
    }, [order]);

    return (

        <View>
            <ViewHeader title="View/Edit Order" back menu={
                <ViewHeaderMenu label="Actions" icon={faTools} options={[
                    {
                        label: "View in Clickup",
                        icon: faNetworkWired,
                        onClick: () => window.open(`https://app.clickup.com/t/${order?.clickup_id}`, "_blank")
                    }
                ]} />
            } />

            <Body>

                {/* Show the media in the form of a slide show */}
                {order?.am_order?.amOrderMedia?.length > 0 && (
                    <SlideshowHeader images={order?.am_order?.amOrderMedia?.map((item: any) => item.url) || []} />
                )}
                <TwoColumn >

                    {/* Static Details */}
                    <Text label="Lead Customer" value={team?.lead_customer ? `${team?.lead_customer?.first_name} ${team?.lead_customer?.last_name}` : "N/A"} />
                    <Text label="Team" value={order?.team?.name || "N/A"} />

                    {/* List out the org and the agency */}
                    <Text label="Corporation" value={team?.agency?.corporation?.name || "N/A"} />
                    <Text label="Agency" value={team?.agency?.name || "N/A"} />


                    {/* Dump the rest */}
                    <Select label="Type" options={select_am_types} value={get_selected_am_type(order?.am_order?.type)} onChange={e => updateAmOrderLocal("type", get_am_type_from_select(e.value))} />
                    <Input label="Address" value={order?.am_order?.address} onChange={e => updateAmOrderLocal("address", e)} />
                    <DateInput label="Date" value={order?.am_order?.date} onChange={e => updateAmOrderLocal("date", e)} />
                    <Textarea label="Notes" value={order?.am_order?.notes} onChange={e => updateAmOrderLocal("notes", e)} />

                    {/* Currency input */}
                    <CurrencyInputWrapped label="Budget" value={order?.am_order?.budget} onChange={e => updateAmOrderLocal("budget", e)} />

                    {/* Status */}
                    <Select label="Status" options={select_order_status} value={select_order_status?.find((s: any) =>
                        s.value === order?.status
                    )} onChange={e => localUpdaate("status", e.value)} />

                    <Input label="Facebook Ad Id" value={order?.facebook_ad_id || ""} onChange={e => localUpdaate("facebook_ad_id", e)} />
                    <Input label="Clickup Id" value={order?.clickup_id || ""} onChange={e => localUpdaate("clickup_id", e)} />

                </TwoColumn>
                <br />
                <Button onClick={save} loading={updating}>
                    Update Order Details
                </Button>


                {/* Allow image control */}
                <div className="media-grid-wrapper">
                    <label>Included Media</label>
                    <div className="media-grid">
                        {order?.am_order?.amOrderMedia?.map((item: any) => (
                            <div className="media-grid-item" key={item.id} >
                                <img src={item.url} alt="" />
                                <div className="remove-btn" onClick={() => handle_remove_image(item.id)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </div>
                            </div>
                        ))}

                        {/* Add another button */}
                        <UploadImageMiniButton onChange={handle_image_upload} endpoint_url="/order/am/media/create-for-staff" />
                    </div>
                </div>

                {/* Button for dawnloading all AM Media */}
                {order?.am_order?.token && <Button onClick={() => download_images()}>
                    Download All Media
                </Button>}
            </Body>

        </View >
    )
}