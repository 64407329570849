import { useEffect, useInsertionEffect, useState } from "react";
import { Staff } from "../../defs/staff-model";
import { useNavigate, useParams } from "react-router-dom";
import { ViewHeader } from "../../comps/ViewHeader";
import { View } from "../../layout/View";
import { Body } from "../../layout/BodyLayout";
import { FormLayout } from "../../layout/FormLayout";
import { UploadProfilePicture } from "../../comps/UploadProfilePicture";
import { getStaffMember, removeStaffMember, updateStaffMember } from "../../api/staff.api";
import { Input } from "../../comps/Input";
import { Button } from "../../comps/Button";
import { Error } from "../../comps/Error";
import { ViewHeaderMenu } from "../../comps/ViewHeaderMenu";
import { faTools, faTrashAlt } from "@fortawesome/free-solid-svg-icons";


export const ViewStaffView = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [staff, setStaff] = useState<Staff>(null!);
    const [loading, setLoading] = useState<boolean>(false);
    const [updating, setUpdating] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    // Locally update the staff member
    const update = (key: string, field: any) => {
        setStaff({ ...staff, [key]: field });
    }

    const load = async () => {
        if (!id) return;
        setLoading(true);

        try {
            const temp = await getStaffMember(id);
            setStaff(temp);
        } catch (err) {
            setError("Failed to load the staff member.");
        }

        setLoading(false);
    }

    // Update the Staff member
    const updateRemote = async () => {
        if (loading || !id) return;
        setUpdating(true);
        try {
            const res = await updateStaffMember(id, staff);
            load();
        } catch (Err) {
            setError("Failed to update the staff memeber");
        }
        setUpdating(false);
    }

    // Handle removing the staff member
    const remove = async () => {
        if (!id || loading || updating) return;
        setLoading(true);
        try {
            await removeStaffMember(id);
            navigate("/staff/staff");
        } catch (err) {
            setError("Failed to remove the staff member.");
        }
        setLoading(false);
    }


    useEffect(() => {
        if (id) load();
    }, [id]);


    return (
        <View>
            <ViewHeader title="View Staff Member" back
                menu={
                    <ViewHeaderMenu label="Actions" icon={faTools} options={[
                        {
                            label: "Remove Staff Member",
                            icon: faTrashAlt,
                            onClick: () => remove()
                        }
                    ]} />
                } />

            <Body>
                <FormLayout>
                    <Input label="First Name" value={staff?.first_name} onChange={v => update("first_name", v)} />
                    <Input label="Last Name" value={staff?.last_name} onChange={v => update("last_name", v)} />
                    <Input label="Email" value={staff?.email} onChange={v => update("email", v)} />

                    <Button onClick={() => updateRemote()} loading={loading || updating}>Update</Button>
                    <Error>{error}</Error>
                </FormLayout>
            </Body>
        </View>
    )


}