import "../../styles/integrations-home.view.scss";
import "../../styles/remax-listing.view.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHouse, faPeopleGroup } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react";
import { RemaxListing, address_to_string, query_listings } from "../../api/remax.api";
import { useNavigate } from "react-router-dom";
import { capitalize } from "lodash";
import { View } from "../../layout/View";
import { ViewHeader } from "../../comps/ViewHeader";
import { Body } from "../../layout/BodyLayout";
import { Serachbar } from "../../comps/Searchbar";
import { List } from "../../layout/ListLayout";
import { Button } from "../../comps/Button";

export const ViewRemaxIntegrationsView = () => {
    const navigate = useNavigate();

    return (
        <View>
            <ViewHeader title="Remax Integration" />

            <div className="integration-cards">
                <div className="integration-card" onClick={() => navigate("/staff/integrations/remax/clients")}>
                    <FontAwesomeIcon icon={faPeopleGroup} />
                    <div className="integration-card-title">
                        Clients
                    </div>
                </div>
                <div className="integration-card" onClick={() => navigate("/staff/integrations/remax/listings")}>
                    <FontAwesomeIcon icon={faHouse} />
                    <div className="integration-card-title">
                        Listings
                    </div>
                </div>

            </div>
        </View>
    );
}
const PAGE_LIMIT = 15;

export const ViewAllRemaxListingsView = () => {
    const navigate = useNavigate();
    const [listings, setListings] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [end, setEnd] = useState(false);

    const loadListings = async (page: number = 0) => {
        setLoading(true);
        setError('');
        setPage(page);

        try {
            const new_listings = await query_listings(query, page);

            if (page > 0) setListings(prev_listings => [...prev_listings, ...new_listings]);
            else setListings(new_listings);

            if (new_listings?.length < PAGE_LIMIT) setEnd(true);
        } catch (err) {
            console.error('Failed to get the listings.');
            setError('Failed to get the listings.');
        }

        setLoading(false);
    };

    useEffect(() => {
        loadListings();
    }, []);

    const handleSearch = () => {
        setEnd(false);
        loadListings();
    };

    return (
        <View>
            <ViewHeader title="Remax Listings" back />

            <Body>
                <Serachbar query={query} setQuery={setQuery} onSearch={handleSearch} loading={loading} />
                {/* <Search>
                    <Input
                        placeholder="Search Remax Listings"
                        value={query}
                        onChange={setQuery}
                        onEnter={handleSearch}
                    />
                    <Button onClick={handleSearch}>Search</Button>
                </Search> */}

                <List>
                    {error && <div className="error">{error}</div>}
                    {listings?.map((listing, i) => (
                        <RemaxListingListItem
                            listing={listing}
                            key={i}
                            onClick={() => navigate(`/staff/remax/listings/${listing?.remax_id}`)}
                        />
                    ))}
                    {/* <ListLoader loading={loading} /> */}
                    {!end && (<Button
                        onClick={() => loadListings(page + 1)}
                    >
                        Load More
                    </Button>)}
                    {/* <LoadMore loading={loading} loadMore={() => loadListings(page + 1)} end={end} /> */}
                </List>
            </Body>
        </View>
    );
};


export const RemaxListingListItem = ({ listing, onClick }: { listing: any, onClick: any }) => {
    return (
        <div className="remax-listing-list-item" onClick={onClick}>
            <div className="left">
                <div className="address">
                    {listing.address || "Failed to format address."}
                </div>
            </div>

            <div className="right">
                {/* Type */}
                {/* <div className="rlli-field">
                    <span id="accent">Type:</span><br />
                    {capitalize(listing?.type)}
                </div> */}

                {/* Status */}
                {/* <div className="rlli-field">
                    <span id="accent">Status:</span><br />
                    {capitalize(listing?.status)}
                </div> */}

                {/* Agents */}
                <div className="rlli-field">
                    <span id="accent">Agents:</span>
                    {listing.agent_name}
                </div>
            </div>
        </div>
    )
}