import "../../styles/view-vpa-order.view.scss";
import { useEffect, useState } from "react"
import { Order, Package, get_package_from_select, get_selected_select_package, select_order_status, select_packages } from "../../defs/order-model";
import { useNavigate, useParams } from "react-router-dom";
import { fetchOrder, getTeam, removeOrder, updateAmOrder, updateVpaOrder } from "../../api/order.api";
import { View } from "../../layout/View";
import { ViewHeader } from "../../comps/ViewHeader";
import { Body } from "../../layout/BodyLayout";
import { Text } from "../../comps/Text";
import { format } from "date-fns";
import { Select } from "../../comps/Select";
import { Button } from "../../comps/Button";
import { FormLayout } from "../../layout/FormLayout";
import { Input } from "../../comps/Input";
import { set } from "lodash";
import { TwoColumn } from "../../layout/TwoColumn";
import { DateInput } from "../../comps/DateInput";
import { getPacakges } from "../../api/packages.api";
import { ViewHeaderMenu } from "../../comps/ViewHeaderMenu";
import { faNetworkWired, faTools, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Team } from "../../defs/team-model";
import { Textarea } from "../../comps/TextArea";

export const ViewVpaOrder = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [order, setOrder] = useState<Order>(null!);
    const [error, setError] = useState<string>("");
    const [packages, setPackages] = useState<Package[]>([]);
    const [team, setTeam] = useState<Team>(null!);
    const [team_loadiong, setTeamLoading] = useState(true);

    // Load a team
    const load_team = async () => {

        if (!order) return;
        setTeamLoading(true);

        try {
            const team = await getTeam(order.team.id);
            setTeam(team);
        } catch (err) {
            setError('Failed to load the team information.')
        }

        setTeamLoading(false);
    }

    // Local update
    const localUpdaate = (field: string, value: any) => {
        setOrder({ ...order, [field]: value });
    }

    // Updaet vpa_order
    const updateVpaOrderLocal = (field: string, value: any) => {
        const new_order = { ...order };
        setOrder(set(new_order, `vpa_order.${field}`, value));
        // setOrder({ ...order, vpa_order: { ...order.vpa_order, [field]: value } });
    }


    // Save
    const save = async () => {
        if (!id) return;
        setUpdating(true);
        setError("");

        try {
            const res = await updateVpaOrder(id, {
                ...order,
                ...order.vpa_order
            });
            load();
        } catch (err) {
            setError("Failed to update the order.");
        }

        setUpdating(false);
    }

    // Load
    const load = async () => {
        if (!id) return;

        setLoading(true);
        setError("");

        try {
            const temp = await fetchOrder(id);
            setOrder(temp);
        } catch (err) {
            setError("Failed to load the order.");
        }

        setLoading(false);
    }

    // Load all the packages to select from
    const load_packages = async () => {
        if (!id) return;

        setLoading(true);
        try {
            const packages = await getPacakges();
            setPackages(packages);
        } catch (err) {
            setError("Failed to load the pacakges.");
        }
        setLoading(false);
    }

    // Remove an order
    const remove = async () => {
        if (!id) return;
        setLoading(true);

        try {
            const res = await removeOrder(+id);
            navigate('/staff/orders');
        } catch (err) {
            setError("Failed to remove the order.");
        }

        setLoading(false);
    }

    useEffect(() => {
        if (id) {
            load();
            load_packages();
        }
    }, [id]);

    useEffect(() => {
        if (order) load_team();
    }, [order])

    return (
        <View>
            <ViewHeader title="View/Edit Order" back
                menu={
                    <ViewHeaderMenu label="Actions" icon={faTools} options={[
                        {
                            label: "Remove Order",
                            icon: faTrashAlt,
                            onClick: () => remove()
                        },
                        {
                            label: "View in Clickup",
                            icon: faNetworkWired,
                            onClick: () => window.open(`https://app.clickup.com/t/${order?.clickup_id}`, "_blank")
                        }
                    ]} />
                } />

            <Body>
                <div className="order-header-image"
                    style={{
                        backgroundImage: `url(${order?.vpa_order?.header_image})`
                    }}
                >
                    {!order?.vpa_order?.header_image && <div className="order-header-image-overlay">
                        <h2>Paste Image URL in header image input below to update.</h2>
                    </div>}
                </div>

                <TwoColumn >
                    <Text label="Lead Customer" value={team?.lead_customer ? `${team?.lead_customer?.first_name} ${team?.lead_customer?.last_name}` : "N/A"} />
                    <Text label="Team" value={order?.team?.name || "N/A"} />

                    {/* List out the org and the agency */}
                    <Text label="Corporation" value={team?.agency?.corporation?.name || "N/A"} />
                    <Text label="Agency" value={team?.agency?.name || "N/A"} />

                    <Text label="Created At" value={order?.created_at && format(new Date(order?.created_at), "dd MMM yyyy HH:mm a")} />
                    <Text label="Last Updated At" value={order?.updated_at && format(new Date(order?.updated_at), "dd MMM yyyy HH:mm a")} />

                    {/* Inputs */}
                    <Input label="Address" value={order?.vpa_order?.address} onChange={e => updateVpaOrderLocal("address", e)} />
                    <Select label="Package" options={select_packages(packages)} value={get_selected_select_package(packages, order?.vpa_order?.pack?.id)} onChange={e => updateVpaOrderLocal("pack", get_package_from_select(packages, e.value))} />
                    <DateInput label="Start Date" value={order?.vpa_order?.start_date && new Date(order?.vpa_order?.start_date)} onChange={e => updateVpaOrderLocal("start_date", e)} />
                    <DateInput label="End Date" value={order?.vpa_order?.end_date && new Date(order?.vpa_order?.end_date)} onChange={e => updateVpaOrderLocal("end_date", e)} />

                    <Select label="Status" options={select_order_status} value={select_order_status?.find((s: any) =>
                        s.value === order?.status
                    )} onChange={e => localUpdaate("status", e.value)} />
                    <Input label="Facebook Ad Id" value={order?.facebook_ad_id || ""} onChange={e => localUpdaate("facebook_ad_id", e)} />
                    <Input label="Header Image" value={order?.vpa_order?.header_image} onChange={e => updateVpaOrderLocal("header_image", e)} />
                    <Input label="Clickup ID" value={order?.clickup_id || ""} onChange={e => localUpdaate("clickup_id", e)} />
                    <Textarea label="Notes" value={order?.vpa_order?.notes || ""} onChange={e => updateVpaOrderLocal("notes", e)} />
                    <br />

                    <Button onClick={save} loading={updating}>
                        Update Order
                    </Button>
                </TwoColumn>
            </Body>

        </View>
    )
}