import '../styles/dash.layout.scss';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
    faBoxesPacking,
    faBuilding,
    faBuildingFlag,
    faFileInvoice,
    faHome,
    faNetworkWired,
    faPeopleGroup,
    faPeopleLine,
    faSignHanging,
    faUserAlt,
    faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { useUserStore } from '../stores/user-store';
import { SidePanel } from '../comps/SidePanel';
import { SidePanelButton } from '../comps/SidePanelButton';
import { CopyBar } from '../comps/CopyBar';
import { Toaster } from 'react-hot-toast';

// Render the staff layout
export const StaffLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const user_auth = useUserStore((state: any) => state.user);
    const [page, setPage] = useState('');

    // Look at the URL and determine what side panel button should be highlighted
    useEffect(() => {
        // Split the location pathname
        let paths = location.pathname.split('/');
        let path = paths.length === 2 ? '/' : paths[2];

        // Check the location
        if (path === 'home') {
            setPage('home');
        } else if (path === 'orders') {
            setPage('orders');
        } else if (path === 'clients') {
            setPage('clients');
        } else if (path === 'teams') {
            setPage("teams")
        } else if (path === 'agencies') {
            setPage('agencies');
        } else if (path === 'org') {
            setPage("org");
        } else if (path === 'listings') {
            setPage('listings');
        } else if (path === 'board') {
            setPage('board');
        } else if (path === 'package') {
            setPage('package');
        } else if (path === 'staff') {
            setPage('staff');
        } else if (path === 'agentbox') {
            setPage('agentbox');
        } else if (path === "remax") {
            setPage("remax");
        }
        else if (path === "agent-marketing") {
            setPage("agent-marketing")
        } else {
            setPage('');
        }
    }, [location]);

    useEffect(() => {
        if (!user_auth) {
            navigate('/login');
        }
    }, [user_auth, navigate]);

    return (
        <div className="staff-layout dash-layout">
            <Toaster />
            {/* Side panel */}
            <SidePanel>
                <div className="inner-menu-items">
                    <SidePanelButton
                        icon={faHome}
                        label="Home"
                        active={page === 'home'}
                        navigate="home"
                    />
                    <SidePanelButton
                        icon={faFileInvoice}
                        label="Orders"
                        active={page === 'orders'}
                        navigate="orders"
                    />
                    <SidePanelButton
                        icon={faBuilding}
                        label="Organisations"
                        active={page === "org"}
                        navigate='org'
                    />
                    <SidePanelButton
                        icon={faBuildingFlag}
                        label="Agency"
                        active={page === 'agencies'}
                        navigate="agencies"
                    />
                    <SidePanelButton
                        icon={faUsers}
                        label="Clients"
                        active={page === 'clients'}
                        navigate="clients"
                    />
                    <SidePanelButton icon={faPeopleLine} label="Teams" active={page === "teams"} navigate="teams" />
                    <SidePanelButton
                        icon={faBoxesPacking}
                        label="Packages"
                        active={page === 'package'}
                        navigate="package"
                    />
                    <SidePanelButton
                        icon={faPeopleGroup}
                        label="Staff"
                        active={page === 'staff'}
                        navigate="staff"
                    />
                    <SidePanelButton
                        icon={faNetworkWired}
                        label="Remax"
                        active={page === "remax"}
                        navigate='remax'
                    />
                </div>
            </SidePanel>

            <div className="content">
                <Outlet />
            </div>

            <CopyBar />
        </div>
    );
};
