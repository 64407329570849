import { client } from "./api.config";

export const login = async (email: string, password: string, setError?: (error: string) => void) => {
    try {
        const res = await client().post("/staff/login", {
            email,
            password
        });

        if (res.status !== 200) {
            throw new Error("Invalid credentials");
        }

        return res.data;
    } catch (err) {
        if (setError) setError("Invalid credentials");
        return null;
    }
}

// Query get the staff member
export const queryStaffMembers = async (query: string) => {
    const res = await client().get("/staff", {
        params: { query }
    });
    return res.data;
}

// Get a single staff member via their id
export const getStaffMember = async (id: string | number) => {
    const res = await client().get(`/staff/${id}`);
    return res.data;
}

// Create a staff member
export const createStaffMember = async (body: any) => {
    const res = await client().post("/staff", body);
    return res;
}

// Update a staff member
export const updateStaffMember = async (id: string, body: any) => {
    const res = await client().put(`/staff/${id}`, body);
    return res;
}

// Remove a staff member
export const removeStaffMember = async (id: string | number) => {
    return await client().delete(`/staff/${id}`);
}