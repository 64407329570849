import "../styles/add-team-member.modal.scss";
import { useEffect, useState } from "react";
import { Customer } from "../defs/customer-model";
import { Team } from "../defs/team-model";
import { ModalLayout } from "./ModalLayout";
import { fetchAgencyCustomers } from "../api/agency.api";
import { addTeamMemberToTeam } from "../api/team.api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { InlineLoader } from "./InlineLoader";
import { accent_color } from "../defs/color.def";

interface Props {
    team: Team;
    onAdd: () => void;
}

export const AddTeamMemberModal = (props: Props) => {
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [adding, setAdding] = useState(false);

    // Load all the customers within the agency to choose from
    const load_customers = async () => {
        setError("");
        setLoading(true);

        try {
            const temp = await fetchAgencyCustomers(props.team.agency_id);

            // Remove the customers that are already in the team
            temp.forEach((customer: Customer) => {
                if (props.team.members.find((m) => m.customer.id === customer.id)) {
                    temp.splice(temp.indexOf(customer), 1);
                }
            });

            setCustomers(temp);
        } catch (err) {
            setError("Failed to load the customers...");
        }

        setLoading(false);
    }

    // Add customer to the team
    const add = async (customer: Customer) => {
        setAdding(true);
        setError("");

        try {
            await addTeamMemberToTeam(props.team.id, customer.id);

            // Remove the customer from the list
            setCustomers(customers.filter((c) => c.id !== customer.id));

            props.onAdd();
        } catch (err) {
            setError("Failed to add the customer to the team...");
        }

        setAdding(false);
    }


    useEffect(() => {
        load_customers();
    }, [props.team]);

    return (
        <ModalLayout>
            <label>Select Team Members</label>
            <div className='team-members'>
                {customers?.map((customer) => (
                    <div className="customer" key={customer.id} id={
                        props.team.members.find((m) => m.customer.id === customer.id) ? "selected" : ""
                    }>
                        <div className="name">{customer.first_name} {customer.last_name}</div>
                        <div className="add-btn" onClick={() => add(customer)}>
                            <FontAwesomeIcon icon={faPlus} />
                        </div>
                    </div>
                ))}
            </div>

            {customers.length === 0 && <p className="info">No customers available...</p>}
            {error && <p className="error">{error}</p>}
            {loading && <InlineLoader color={accent_color} />}
        </ModalLayout >
    )

}
