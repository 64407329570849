import { useState } from "react"
import { ViewHeader } from "../../comps/ViewHeader";
import { View } from "../../layout/View";


export const StaffHomeView = () => {


    return (
        <View>
            <ViewHeader title={""} />

            {/* <FullPageConfetti trigger={true} /> */}

            {/* <NumberCountGrid>
                <NumberCount title="VPA's Created" count={total_vpa || 0} desc="The number of VPA's created today." loading={loading} />
                <NumberCount title="VPA's Completed" count={total_vpa_completed || 0} desc="The number of VPA's completed today." />
                <NumberCount title="VPA's to Complete" count={total_vpa_to_complete || 0} desc="The number of VPA's left to complete." />
            </NumberCountGrid> */}

            <div className="title">Welcome to the Staff Dashboard</div>
            <div className="subtitle">Select an option from the side panel to get started.</div>
        </View>
    );
}