import "../../styles/view-all-agencies-view.scss";
import { useEffect, useState } from "react"
import { ViewHeader } from "../../comps/ViewHeader"
import { Body } from "../../layout/BodyLayout"
import { View } from "../../layout/View"
import { Agency } from "../../defs/agency-model"
import { queryAgencies } from "../../api/agency.api"
import { Serachbar } from "../../comps/Searchbar"
import { List } from "../../layout/ListLayout"
import { ViewHeaderMenu } from "../../comps/ViewHeaderMenu";
import { faCirclePlus, faTools } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";


export const ViewAllAgencies = () => {
    const navigate = useNavigate();
    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [agencies, setAgencies] = useState<Agency[]>([]);

    // Query the agencies
    const runQuery = async () => {
        setLoading(true);

        try {
            const res = await queryAgencies(query);
            setAgencies(res);
        } catch (err) {
            setError("Failed to query the agencies.");
        }

        setLoading(false);
    }


    useEffect(() => {
        runQuery();
    }, []);


    return (
        <View>
            <ViewHeader title="View All Agencies" back
                menu={
                    <ViewHeaderMenu label="Actions" icon={faTools} options={[
                        {
                            label: "Create Agency",
                            icon: faCirclePlus,
                            onClick: () => navigate("/staff/agencies/create")
                        }
                    ]} />
                }
            />
            <Body>
                <Serachbar query={query} setQuery={setQuery} onSearch={runQuery} loading={loading} />

                <List>
                    {agencies?.map((agency: Agency, index: number) => (
                        <div className="agency-list-item" onClick={() => navigate(`/staff/agencies/${agency.id}`)}>
                            {/* Agency logo - Use the corp logo if there is not agency logo */}
                            <div className="logo" style={{ backgroundImage: `url('${agency?.logo ? agency.logo : agency?.corporation?.logo}')` }} />

                            {/* Agency details */}
                            <div className="agency-details">
                                <div className="name">{agency?.name}</div>
                                <div className="corp">{agency?.corporation?.name}</div>
                            </div>

                            {/* Agency stats */}
                            {/* <div className="agency-stats">
                                <div className="stats-item">
                                    <div className="label">Members</div>
                                    <div className="value">{agency?.members?.length}</div>
                                </div>
                                <div className="stats-item">
                                    <div className="label">Jobs</div>
                                    <div className="value">{agency?.jobs?.length}</div>
                                </div>
                            </div> */}
                        </div>
                    ))}
                </List>
            </Body>
        </View>
    )

}