export enum Role {
    CORP_ADMIN = 'CORP_ADMIN',
    AGENCY_ADMIN = 'AGENCY_ADMIN',
    AGENT = 'AGENT',
    ASSISTANT = 'ASSISTANT',
    VIEWER = 'VIEWER'
};

export const select_roles = [
    {
        label: 'Corporate Admin',
        value: Role.CORP_ADMIN
    },
    {
        label: 'Agency Admin',
        value: Role.AGENCY_ADMIN
    },
    {
        label: 'Agent',
        value: Role.AGENT
    },
    {
        label: 'Assistant',
        value: Role.ASSISTANT
    },
    // {
    //     label: 'VIEWER',
    //     value: Role.VIEWER
    // }
]

export enum ResetType {
    ONBOARD = 'ONBOARD',
}

export interface CustomerReset {
    created_at: Date;
    customer_id: number;
    expire_at: Date;
    id: number;
    token: string;
    type: ResetType;
}

export interface Customer {
    id: number;
    email: string;
    password: string;
    first_name: string;
    last_name: string;
    created_at: Date;
    updated_at: Date;
    last_sign_in: Date | null;
    dp: string | null;
    mobile: string | null;
    agency_id: number;
    role: Role;
    agency: any;
    team: any;
    meta_ad_account_id?: string;
    resets?: CustomerReset[];
}

// Render the customer roles to a pretty text
export const renderRole = (role: Role) => {
    switch (role) {
        case Role.CORP_ADMIN:
            return 'Corporate Admin';
        case Role.AGENCY_ADMIN:
            return 'Agency Admin';
        case Role.AGENT:
            return 'Agent';
        case Role.ASSISTANT:
            return 'Assistant';
        case Role.VIEWER:
            return 'Viewer';
        default:
            return 'Unknown';
    }
}