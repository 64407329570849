import { AmType } from "../defs/order-model";
import { client } from "./api.config"

export const fetchOrder = async (id: string) => {
    const resp = await client().get(`/order/${id}`);
    return resp.data;
}

export const getOrders = async (query: string) => {
    const resp = await client().get(`/order`, { params: { query } });
    return resp.data;
}

// Update an order
export const updateOrder = async (id: string, data: any) => {
    const resp = await client().put(`/order/${id}`, data);
    return resp.data;
}

// Update a VPA order
export const updateVpaOrder = async (id: string, data: any) => {
    const resp = await client().put(`/order/vpa/${id}`, data);
    return resp.data;
}

// Update an AM order
export const updateAmOrder = async (id: string, data: any) => {
    const resp = await client().put(`/order/am/${id}`, data);
    return resp.data;
}

// Remove an order
export const removeOrder = async (id: number) => {
    const resp = await client().delete(`/order/${id}`);
    return resp.data;
}

// Create an order
export const createVpaOrder = async (data: any) => {
    const res = await client().post(`/order-for-staff/vpa`, data);
    return res.data;
}

// Create an order for am
export const createAmOrder = async (data: any) => {
    const res = await client().post(`/order-for-staff/am`, data);
    return res.data;
}

// Helper function for generating the type
export const convert_type = (type: AmType) => {
    switch (type) {
        case AmType.SOLD:
            return "Property Sold";
        case AmType.TEST:
            return "Testimonial";
        case AmType.ECO:
            return "Ecosystem"
        default:
            return ""
    }
}


// Get a team based on it's id
export const getTeam = async (team_id: string | number) => {
    const res = await client().get(`/team/${team_id}`);
    return res.data;
}

// This function adds an image to an agent marketing order
export const addAgentMarketinMedia = async (order_id: number | string, url: string) => {
    const res = await client().post(`/order/am/${order_id}/media-for-staff`, { url });;
    return res.data;
}


// Remove an agent marketing image
export const removeAgentMarketingMedia = async (order_id: number | string, media_id: number | string) => {
    //order/am/:id/media-for-staff/:media_id
    const res = await client().delete(`/order/am/${order_id}/media-for-staff/${media_id}`);
    return res.data;
}

export const createAmOrderForStaff = async (order: any) => {
    const res = await client().post(`/order-for-staff/am`, order);
    return res.data;
}

// Download the images for an AM Order
export const downloadAmMediaImages = async (token: string) => {
    const res = await client().get(`/order/${token}/am-media`, {
        responseType: "blob"
    });
    return res.data;
}
