import "../../styles/create-agency-view.scss";
import { useEffect, useState } from "react"
import { ViewHeader } from "../../comps/ViewHeader"
import { Body } from "../../layout/BodyLayout"
import { FormLayout } from "../../layout/FormLayout"
import { View } from "../../layout/View"
import { loadOrgs } from "../../api/org.api"
import { Input } from "../../comps/Input"
import { Button } from "../../comps/Button"
import { addAgency } from "../../api/agency.api"
import { useNavigate } from "react-router-dom"


export const CreateAgencyView = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [creating, setCreating] = useState(false);
    const [error, setError] = useState("");
    const [corps, setCorps] = useState<any[]>([]);
    const [name, setName] = useState("");
    const [corp, setCorp] = useState<number>(null!);

    // Create the agency
    const create = async () => {
        setCreating(true);
        setError("");

        try {
            const res = await addAgency({
                corporation_id: corp,
                name: name,
                address: ""
            });
            navigate("/staff/agencies");
        } catch (err) {
            setError("Failed to create the agency.");
        }

        setCreating(false);
    }

    // Load the corporations/organizations
    const load = async () => {
        setLoading(true);
        setError("");

        try {
            const res = await loadOrgs();
            setCorps(res);
        } catch (err) {
            setError("Failed to load the corporations.");
        }

        setLoading(false);
    }

    useEffect(() => {
        load();
    }, []);

    console.log("Got the corp: ", corp);

    return (
        <View>
            <ViewHeader title="Create Agency" back />


            <Body>
                <FormLayout>
                    {/* List out the corps */}

                    <div className="select-orgs">
                        <label>Select an organization</label>

                        <div className="orgs">
                            {corps?.map((c, i) => (
                                <div className="org" key={i} id={
                                    c.id === corp ? "selected" : ""
                                } onClick={() => setCorp(c.id)}>
                                    <label htmlFor={`corp-${i}`}>{c.name}</label>
                                </div>
                            ))}
                        </div>
                    </div>

                    <Input label="Name" value={name} onChange={setName} />
                    <br />

                    <Button onClick={create} loading={creating}>
                        Create!
                    </Button>


                </FormLayout>
            </Body>
        </View >
    )

}