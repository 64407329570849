import { client } from "./api.config"


// Query the teams
export const getTeams = async (q: string) => {
    const resp = await client().get(`/team`, { params: { q } });
    return resp.data;
}

// Get a single team
export const getTeam = async (id: string | number) => {
    const resp = await client().get(`/team/${id}`);
    return resp.data;
}

// Update a team
export const updateTeam = async (id: string | number, data: any) => {
    const resp = await client().put(`/team/${id}`, data);
    return resp.data;
}

// Delete a team
export const deleteTeam = async (id: string | number) => {
    const resp = await client().delete(`/team/${id}`);
    return resp.data;
}

// Add a customer to a team
export const addTeamMemberToTeam = async (team_id: number, customer_id: number) => {
    const res = await client().post(`/team/${team_id}/member/${customer_id}`);
    return res.data;
}

// Set the lead customer for a team
export const setTeamLeadCustomer = async (team_id: number, customer_id: number) => {
    const res = await client().post(`/team/${team_id}/leader/${customer_id}`);
    return res.data;
}

// Remove a customer from a team
export const removeTeamMemberFromTeam = async (team_id: number, customer_id: number) => {
    const res = await client().delete(`/team/${team_id}/member/${customer_id}`);
    return res.data;
}

// Connect team into clickup
export const connectTeamToClickup = async (team_id: number) => {
    const res = await client().post(`/team/${team_id}/clickup-connect`);

    return res.data;
}