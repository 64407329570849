import { client } from "./api.config"


// Router for creating an orgnaization
export const createOrg = async (name: string) => {
    const res = await client().post('/corporation', { name });
    return res.data;
}

// Load the orgs
export const loadOrgs = async () => {
    const res = await client().get('/corporation');
    return res.data;
}


// Query the orgs
export const queryOrgs = async (query: string) => {
    const res = await client().get("/corporations", {
        params: { query }
    });

    return res.data;
}

// Get a single org
export const getOrg = async (id: string) => {
    const res = await client().get(`/corporation/${id}`);
    return res.data;
}

// Update org logo
export const updateOrgLogo = async (id: number, form: FormData) => {
    const res = await client().post(`/corporation/${id}/logo`, form);
    return res.data;
}

// Update an org
export const updateOrg = async (id: number | string, org: any) => {
    const res = await client().put(`/corporation/${id}`, org);
    return res.data;
}

// Remove an org
export const removeOrg = async (id: number | string) => {
    const res = await client().delete(`/corporation/${id}`);
    return res.data;
}

// Get the agencies for an org
export const getAgenciesViaOrg = async (id: number | string) => {
    const res = await client().get(`/corporation/${id}/agency`);
    return res.data;
}