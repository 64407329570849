import { useRef } from "react";
import "../styles/checkbox.comp.scss"
import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";


interface Props {
    value?: boolean;
    onChange: (v: boolean) => void;
    label: string;
    disabled?: boolean;
}

export const Checkbox = (props: Props) => {
    const ref = useRef<HTMLInputElement>(null);


    const handle_click = () => {
        if (props.disabled) return;
        props.onChange(!props.value);
    }

    return (
        <div className="checkbox-wrapper" onClick={handle_click}>
            <input
                ref={ref}
                type="checkbox"
                checked={props.value}
                onChange={(e) => props.onChange(e.target.checked)}
                disabled={props.disabled}
            />

            <div className="check">
                <AnimatePresence>
                    {props.value && (
                        <motion.div
                            className="check-icon"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 0.2,
                            }}
                        >
                            <FontAwesomeIcon icon={faCheck} />
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
            <label>{props.label}</label>
        </div>
    )
}