import { InlineLoader } from "../comps/InlineLoader"
import "../styles/form.layout.scss"
import { ReactNode } from "react"

export const FormLayout = ({ children, loading, styles }: { children: ReactNode, loading?: boolean, styles?: any }) => {

    return (
        <div className="form" style={{
            ...styles
        }}>
            {loading ? <InlineLoader color="#404e3d" /> : children}
        </div>
    )
}