import { useState } from "react";
import { View } from "../../layout/View"
import { Staff } from "../../defs/staff-model";
import { ViewHeader } from "../../comps/ViewHeader";
import { Body } from "../../layout/BodyLayout";
import { FormLayout } from "../../layout/FormLayout";
import { Input } from "../../comps/Input";
import { Button } from "../../comps/Button";
import { createStaffMember } from "../../api/staff.api";
import { useNavigate } from "react-router-dom";

export const CreateStaffView = () => {
    const navigate = useNavigate();
    const [new_staff, setNewStaff] = useState<Staff | any>({
        first_name: '',
        last_name: "",
        email: "",
        password: ""
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    // Local update
    const update = (key: string, value: any) => {
        setNewStaff({ ...new_staff, [key]: value });
    }

    // Create the Staff Member
    const create = async () => {
        setLoading(true);
        setError("");

        try {
            const res = await createStaffMember(new_staff);

            if (res.status === 201) {
                navigate("/staff/staff");
            }
        } catch (err) {
            setError("Failed to create the staff member.");
        }

        setLoading(false);
    }

    return (
        <View>

            <ViewHeader title="Create Staff Member" back />

            <Body>

                <FormLayout>
                    <Input label="First Name" value={new_staff.first_name} onChange={v => update("first_name", v)} />
                    <Input label="Last Name" value={new_staff.last_name} onChange={v => update("last_name", v)} />
                    <Input label="Email" value={new_staff.email} onChange={v => update("email", v)} />
                    <Input label="Password" value={new_staff.password} onChange={v => update("password", v)} />

                    <Button onClick={() => create()} loading={loading}>Create</Button>
                </FormLayout>



            </Body>
        </View >
    )

}
