import "../../styles/create-customer.view.scss";
import { useEffect, useState } from "react"
import { ViewHeader } from "../../comps/ViewHeader"
import { View } from "../../layout/View"
import { fetchAllAgencies } from "../../api/agency.api"
import { Agency } from "../../defs/agency-model"
import { createCustomer } from "../../api/customer.api"
import { Role, select_roles } from "../../defs/customer-model"
import { Select } from "../../comps/Select"
import { Input } from "../../comps/Input"
import { FormLayout } from "../../layout/FormLayout"
import { Body } from "../../layout/BodyLayout"
import { Button } from "../../comps/Button"
import { useNavigate } from "react-router-dom";
import { SelectAgency } from "../../comps/SelectAgency";
import { SelectOrg } from "../../comps/SelectOrg";


export const CreateCustomerView = () => {
    const navigate = useNavigate();
    const [agencies, setAgencies] = useState<Agency[]>([]);


    const [first_name, setFirstName] = useState<string>("")
    const [last_name, setLastName] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [role, setRole] = useState<Role>(null!);
    const [selected_agency, setSelectedAgency] = useState<Agency | null>(null);
    const [agency, setAgency] = useState<number | null>(null);
    const [corp, setCorp] = useState<number | null>(null);

    const [creating, setCreating] = useState(false);
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    // Load every agency
    const load = async () => {
        setLoading(true);
        setError("");

        try {
            const agencies = await fetchAllAgencies();
            setAgencies(agencies);
        } catch (err) {
            setError("Failed to load the agencies.");
        }

        setLoading(false);
    }

    // Create a new customer
    const create = async () => {
        if (!agency) return;
        setCreating(true);
        setError("");

        try {
            await createCustomer({ first_name, last_name, email, agency_id: agency, role: role });
            navigate("/staff/clients");
        } catch (err) {
            setError("Failed to create the customer.");
        }

        setCreating(false);
    }

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        if (!agency) return;
        setAgency(null);
    }, [corp])

    return (
        <View>
            <ViewHeader title="Create Customer" back />

            <Body>
                <h2>Where do they belong?</h2>
                <SelectOrg value={corp} onChange={setCorp} />
                <SelectAgency value={agency} onChange={setAgency} corp={corp || undefined} />

                <h2>Details</h2>
                <FormLayout>
                    <Input type="text" label="First Name" value={first_name} onChange={setFirstName} />
                    <Input type="text" label="Last Name" value={last_name} onChange={setLastName} />
                    <Input type="text" label="Email" value={email} onChange={setEmail} />
                    <Select label="Role" value={select_roles?.find((o: any) => o.value === role)} options={select_roles} onChange={(o: any) => setRole(o.value)} />

                    <br />

                    <Button onClick={create} loading={loading}>Create Staff!</Button>
                </FormLayout>
            </Body>

        </View>
    )
}