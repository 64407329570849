import "../styles/package-item.list.scss"
import { useNavigate } from "react-router-dom"
import { add_commas } from "../utils/numbers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Package } from "../defs/order-model";

export const PackageListItem = ({ pack, onClick, onRemove }: { pack: any, onClick?: (id: number) => void, onRemove?: (id: number) => void }) => {
    const navigate = useNavigate();

    const handle_remove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        if (onRemove) onRemove(pack.id);
    };

    return (
        <div className="package-list-item" onClick={() => onClick ? onClick(pack.id) : navigate(`/staff/package/${pack.id}`)}>
            {/* Remove Action */}
            {onRemove && <div className="remove" onClick={handle_remove}>
                <FontAwesomeIcon icon={faTimesCircle} />
            </div>}

            {/* Header */}
            <div className="left">
                {(pack.team_id || pack.agency_id || pack.corp_id) && (
                    <div className="custom-package">Custom</div>
                )}
                <div className="row">
                    <div className="name">{pack.name}</div>
                    {pack.desc && <div className="desc">{pack.desc}</div>}
                </div>
            </div>

            {/* Footer */}
            <div className="right">
                <div className="detail">
                    <label>Cost</label>
                    <div className="price">${add_commas(pack.price / 100)}</div>
                </div>
                <div className="detail">
                    <label>Impressions</label>
                    <div className="impressions">{add_commas(pack.impressions)}</div>
                </div>
                <div className="detail">
                    <label>Timeframe</label>
                    <div className="timeframe">{pack.min_days}d - {pack.max_days}d</div>
                </div>
            </div>
        </div>
    )
}
/*
<div className="hidden">{pack.hidden ? "Hidden" : "Visible"}</div>
<div className="days">{pack.min_days} - {pack.max_days} days</div>


*/