import { useEffect, useState } from "react"
import { Input } from "../../comps/Input"
import { ViewHeader } from "../../comps/ViewHeader"
import { Body } from "../../layout/BodyLayout"
import { FormLayout } from "../../layout/FormLayout"
import { View } from "../../layout/View"
import { Customer, CustomerReset, ResetType, select_roles } from "../../defs/customer-model"
import { fetchCustomer, removeCustomer, updateCustomer, updateCustomerDisplayPicture } from "../../api/customer.api"
import { UploadProfilePicture } from "../../comps/UploadProfilePicture"
import { Button } from "../../comps/Button"
import { useNavigate, useParams } from "react-router-dom"
import { ViewHeaderMenu } from "../../comps/ViewHeaderMenu"
import { faLink, faTools, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { Error } from "../../comps/Error"
import { Select } from "../../comps/Select"
import toast from "react-hot-toast"
import { fe_remax_url, fe_url } from "../../api/api.config"


export const ViewCustomerView = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [customer, setCustomer] = useState<Customer>(null!);
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState("");

    // Update local
    const updateLocal = (field: string, value: any) => {
        setCustomer({ ...customer, [field]: value });
    }

    // Load the 
    const load = async () => {
        if (!id) return;
        setError("");
        setLoading(true);

        try {
            const temp = await fetchCustomer(id);
            setCustomer(temp);
        } catch (err) {
            setError("Failed to load the Agency...");
        }
    }


    // Update remote
    const save = async () => {
        setLoading(true);
        setError("");

        try {
            await updateCustomer(customer);
            await load();
        } catch (err) {
            setError("Failed to update.");
        }

        setLoading(false);
    }

    // Update the logo
    const updateLogo = async (e: any) => {
        if (!id) return;
        const file = e?.target?.files?.[0];
        if (!file) return;
        setUpdating(true);
        setError("");
        try {
            const form = new FormData();
            form.append("image", file);
            const res = await updateCustomerDisplayPicture(+id, form);
            updateLocal("dp", res?.dp)
        } catch (err) {
            setError("Failed to upload the image.");
        }
        setUpdating(false);
    }

    // Remove the customer
    const remove = async () => {
        if (!id) return;
        console.log('Removing... id: ', id);
        setLoading(true);

        try {
            const res = await removeCustomer(+id);
            navigate("/staff/clients")
        } catch (err) {
            setError("Failed to remove the customer. It most likely has orders, or other dependencies.");
        }

        setLoading(false);
    }

    // Get the link for the onboarding page
    const getOnboardingLink = () => {
        if (!customer) return;

        const reset = customer?.resets?.find((reset: CustomerReset) => reset.type === ResetType.ONBOARD);
        if (!reset) {
            toast("Customer has no reset availble.")
            return;
        }

        // Determin if this customer is a remax client
        const remax = customer?.agency?.corporation?.name === "REMAX";
        const base_url = remax ? fe_remax_url : fe_url;

        // Copy to clickboard
        navigator.clipboard.writeText(`${base_url}/setup?id=${reset.token}`);
        toast("Copied to clipboard.");
    }

    useEffect(() => {
        if (id) load();
    }, [id])

    return (
        <View>
            <ViewHeader title="Edit Customer" back
                menu={
                    <ViewHeaderMenu label="Actions" icon={faTools} options={[
                        {
                            label: "Remove Customer",
                            icon: faTrashAlt,
                            onClick: () => remove()
                        },
                        {
                            label: "Onboarding Link",
                            icon: faLink,
                            onClick: () => getOnboardingLink()
                        }
                    ]} />
                } />

            <Body>
                <div className='org'>
                    <b>Organization:</b> {customer?.agency?.corporation?.name || 'N/A'}
                </div>
                <div className="agency">
                    <b>Agency:</b> {customer?.agency?.name || 'No Agency'}
                </div>

                <FormLayout>
                    <UploadProfilePicture onClick={updateLogo} image={customer?.dp || ''} />

                    <Input label="First Name" value={customer?.first_name} onChange={
                        (e) => updateLocal("first_name", e)
                    } />
                    <Input label="Last Name" value={customer?.last_name} onChange={
                        (e) => updateLocal("last_name", e)
                    } />
                    <Input label="Email" value={customer?.email} onChange={
                        (e) => updateLocal("email", e)
                    } />
                    <Select label="Role" value={select_roles?.find((o: any) => o.value === customer?.role)} options={select_roles} onChange={(o: any) => updateLocal("role", o.value)} />
                    <Input label="Ad Account Id" value={customer?.meta_ad_account_id || ""} onChange={
                        (e) => updateLocal("meta_ad_account_id", e)
                    } />


                    <br />
                    <Button onClick={save} loading={updating}>
                        Save
                    </Button>
                    <Error>{error}</Error>
                </FormLayout>

            </Body>
        </View>
    )
}