import '../styles/customer-item.list.scss';
import { Customer, renderRole } from '../defs/customer-model';
import { useNavigate } from 'react-router-dom';

// The Customer Objects -> List Item for lists
export const CustomerListItem = ({ customer }: { customer: Customer }) => {
    const navigate = useNavigate();

    console.log('Got the customer: ', customer);

    return (
        <div
            className="customer-list-item"
            onClick={() => navigate(`/staff/clients/${customer.id}`)}
            tabIndex={0}
        >
            {/* DP */}
            <div
                className="dp"
                style={{
                    backgroundImage: `url('${customer?.dp
                        ? customer.dp
                        : `https://ui-avatars.com/api/?name=${customer?.first_name}+${customer?.last_name}&background=404e3d&color=fff&size=128`
                        }')`,
                }}
            />

            {/* Full Name */}
            <div className="details">
                <div className="name">
                    {customer.first_name} {customer.last_name}
                </div>
                <div className="email">{customer.email}</div>
            </div>
            <div className="details">
                <div className='org'>
                    <b>Organization:</b> {customer?.agency?.corporation?.name || 'N/A'}
                </div>
                <div className="agency">
                    <b>Agency:</b> {customer?.agency?.name || 'No Agency'}
                </div>
            </div>
            <div className="details">
                <div className="role">
                    <b>Role:</b> {renderRole(customer?.role) || 'No Role'}
                </div>
            </div>
        </div>
    );
};
