import { useEffect, useState } from "react"
import { Serachbar } from "../../comps/Searchbar"
import { ViewHeader } from "../../comps/ViewHeader"
import { Body } from "../../layout/BodyLayout"
import { View } from "../../layout/View"
import { Order } from "../../defs/order-model"
import { getOrders } from "../../api/order.api"
import { List } from "../../layout/ListLayout"
import { OrderListItem } from "../../comps/OrderListItem"
import { Customer } from "../../defs/customer-model"
import { CustomerListItem } from "../../comps/CustomerListItem"
import { getCustomers } from "../../api/customer.api"
import { ViewHeaderMenu } from "../../comps/ViewHeaderMenu"
import { faCirclePlay, faCirclePlus, faTools } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"


export const ViewAllCustomers = () => {
    const navigate = useNavigate();
    const [query, setQuery] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>("")
    const [customers, setCustomers] = useState<Customer[]>([]);

    // Load all the orders
    const load = async () => {
        setLoading(true)
        setError("");

        try {
            const orders = await getCustomers(query)
            setCustomers(orders)
        } catch (err) {
            setError("Failed to load customers")
        }

        setLoading(false)
    }

    useEffect(() => {
        load()
    }, [query])


    return (
        <View>
            <ViewHeader title="View Clients" back
                menu={
                    <ViewHeaderMenu label="Actions" icon={faTools} options={[
                        {
                            label: "Create Customer",
                            icon: faCirclePlus,
                            onClick: () => navigate("/staff/clients/create-customer")
                        }
                    ]} />
                } />

            <Body>
                <Serachbar query={query} setQuery={setQuery} onSearch={load} loading={loading} />

                <List>
                    {customers?.map((customer, index) => (
                        <CustomerListItem key={index} customer={customer} />
                    ))}
                </List>
            </Body>
        </View>
    )
}