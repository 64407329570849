import "../styles/assign-package-modal.scss";
import { useEffect, useState } from "react";
import { Package, PackageClickupOption } from "../defs/order-model";
import { ModalLayout } from "./ModalLayout";
import { getClickupPackageOptions, updatePackage } from "../api/packages.api";
import { InlineLoader } from "./InlineLoader";
import { accent_color } from "../defs/color.def";

interface Props {
    pack: Package;
    onAdd: () => void;
}

export const AssignClickupPackageModal = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [options, setOptions] = useState<PackageClickupOption[]>([]);
    const [updating, setUpdating] = useState(false);

    // Assign the clickup id to the package
    const assign = async (option: PackageClickupOption) => {
        setUpdating(true);
        setError("");

        try {
            await updatePackage(props.pack.id, {
                clickup_id: option.id,
            });

            props.onAdd();
        } catch (err) {
            setError("Failed to assign the clickup id to the package...");
        }

        setUpdating(false);
    }

    // Load the available options
    const load = async () => {
        setLoading(true);

        try {
            const options = await getClickupPackageOptions(props.pack.id);
            setOptions(options);
        } catch (err) {
            setError("Failed to load the options...");
        }

        setLoading(false);
    }

    useEffect(() => {
        if (props.pack) load();
    }, [props.pack]);


    return (
        <ModalLayout>
            <label>Select Clickup Package Option</label>

            {loading && <div className="loader-con">
                <InlineLoader color={accent_color} />
            </div>}

            {!loading && options && <div className="clickup-options">
                {options?.map((option: PackageClickupOption, i: number) => (
                    <div key={i} className="clickup-option" id={option.id === props.pack.clickup_id ? "selected" : ""} onClick={() => assign(option)}>
                        {option.name}
                    </div>
                ))}
            </div>}
        </ModalLayout>
    );
}