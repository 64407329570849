import { client } from "./api.config";

export interface RemaxClientSync {
    id: number;
    status: "syncing" | "failed" | "done";
    started: Date;
    finished: Date;
    count: number;
    added: number;
}

export interface RemaxListing {
    id: string;
    officeID: number;
    listingID: number;
    foreignID: string;
    modifiedDateTime: Date;
    authority: string;
    underOffer: boolean;
    inspections: string;
    type: string;
    status: string;
    headline: string;
    description: string;
    agents: any[];
    address: {
        unitNumber: string;
        lotNumber: string;
        streetNumber: string;
        street: string;
        suburb: string;
        state: string;
        region: string;
        postcode: string;
        country: string;
    };
    images: any[];
    price: {
        price: number;
        view: string;
        display: boolean;
    }
}

/**
 * This route is for downloading a remax listing images zip file
 */
export const download_remax_listing_images = async (listing_id: string) => {
    const res = await client().get(`/remax/listing/${listing_id}/images`, {
        responseType: "blob"
    });

    return res.data;
}


/**
 * This route is for getting the remax listings
 */
export const query_listings = async (query: string, page: number) => {
    const res = await client().get("/remax/listings", {
        params: { query: query, page }
    })

    return res?.data;
}

export const fetch_listing = async (id: string): Promise<RemaxListing> => {
    const res = await client().get(`/remax/listing/${id}`);
    return res.data;
}

export const address_to_string = (listing: RemaxListing) => {
    const { address } = listing;

    // Start building the address string
    let addressString = "";

    // Append unit number if available
    if (address.unitNumber) {
        addressString += `Unit ${address.unitNumber}, `;
    }

    // Append lot number if available
    if (address.lotNumber) {
        addressString += `Lot ${address.lotNumber}, `;
    }

    // Append street number and name
    addressString += `${address.streetNumber} ${address.street}, `;

    // Append suburb
    addressString += `${address.suburb}, `;

    // Append state
    addressString += `${address.state}, `;

    // Append region if available
    if (address.region) {
        addressString += `${address.region}, `;
    }

    // Append postcode
    addressString += `${address.postcode}, `;

    // Append country
    addressString += address.country;

    return addressString;

}

/**
 * This function gets all of the syncing objects from the backend
 */
export const get_sync_objs = async () => {
    const res = await client().get(`/remax/customers/sync`);
    return res?.data;
}
