import { InlineLoader } from "../comps/InlineLoader";
import { accent_color } from "../defs/color.def";
import "../styles/list-layout.scss";

export const List = ({ children, loading }: { children: React.ReactNode, loading?: boolean }) => {
    return (
        <div className="list">
            {children}
            {loading && (
                <InlineLoader color={accent_color} />
            )}
        </div>
    )
}