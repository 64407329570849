import { Customer } from "./customer-model";
import { Team } from "./team-model";

export interface PackageClickupOption {
    id: string;
    name: string;
}

export enum AmType {
    SOLD = "SOLD",
    TEST = "TEST",
    ECO = "ECO"
}

export enum OrderStatus {
    PENDING = "PENDING",
    ACTIVE = "ACTIVE",
    COMPLETED = "COMPLETED",
    CANCELLED = "CANCELLED"
}

export enum OrderType {
    VPA = "VPA",
    AM = "AM"
}

export interface Order {
    id: number;
    team_id: number;
    team: Team;
    created_at: Date;
    updated_at: Date;
    status: OrderStatus;
    facebook_ad_id?: string;
    clickup_id?: string;
    order_type: OrderType;

    vpa_order?: any;
    am_order?: any;
}

export interface NewAmOrder {
    address?: string;
    notes?: string;
    date: Date;
    media: string[];
    team_id?: number | null; // If none provided creates a new team
    customer_id?: number | null;
    budget: number; // Convert to cents
    type: AmType;
}

export interface NewVpaOrder {
    address: string;
    notes: string;
    package_id: number | null;
    package?: Package;
    start_date: Date;
    end_date: Date;
    customer?: Customer;
    team?: Team;
}

export interface Package {
    id: number;
    name: string;
    impressions: number;
    price: number;
    min_days: number;
    max_days: number;
    list_order: number;
    desc: string;
    clickup_id: string;
    team_id: number;
    agency_id: number;
    corp_id: number;
    package_options: PackageOption[];
}

export interface PackageOption {
    id: number;
    name: string;
    checked: boolean;
    package_id: number;
    list_order: number;
    package: Package;
}

/**
 * enum OrderStatus {
  PENDING
  ACTIVE
  COMPLETED
  CANCELLED
}
 */
// Define the statuses for select
export const select_order_status = [
    {
        label: 'Pending',
        value: OrderStatus.PENDING
    },
    {
        label: 'Active',
        value: OrderStatus.ACTIVE
    },
    {
        label: 'Completed',
        value: OrderStatus.COMPLETED
    },
    {
        label: 'Cancelled',
        value: OrderStatus.CANCELLED
    }
]

// Convert the pacakges from a list of packages to a list of select options
export const select_packages = (packages: Package[]) => {
    return packages.map(p => {
        return {
            label: p.name,
            value: p.id
        }
    })
}

// Get the value of a package from a list of packages
export const get_selected_select_package = (packages: Package[], id: number) => {

    const selected = packages.find(p => p.id === id);

    if (selected) {
        return {
            label: selected.name,
            value: selected.id
        }
    }

    return undefined;
}

// Get the value of a package from a list of packages
export const get_package_from_select = (packages: Package[], id: number) => {
    const selected = packages.find(p => p.id === id);

    if (selected) {
        return selected;
    }

    return undefined;
}

// Convert the am order types for select
export const select_am_types = [
    {
        label: 'Sold',
        value: AmType.SOLD
    },
    {
        label: 'Testimonial',
        value: AmType.TEST
    },
    {
        label: 'Ecosystem',
        value: AmType.ECO
    }
];

// Get the select value for an agent marketing type 
export const get_selected_am_type = (type: AmType) => {

    const selected = select_am_types.find(t => t.value === type);

    if (selected) {
        return {
            label: selected.label,
            value: selected.value
        }
    }

    return undefined;

}

// Get the select value for an agent marketing type
export const get_am_type_from_select = (type: string) => {

    const selected = select_am_types.find(t => t.value === type);

    if (selected) {
        return selected.value;
    }

    return undefined;

}