import "../../styles/edit-package-view.scss";
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlerts } from '../../hooks/alert-provider';
import { Package } from '../../defs/order-model';
import { addPackageOption, getPackage, removePackage, updatePackage } from '../../api/packages.api';
import { View } from '../../layout/View';
import { ViewHeader } from '../../comps/ViewHeader';
import { ViewHeaderMenu } from '../../comps/ViewHeaderMenu';
import { faBuilding, faNetworkWired, faTools, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Body } from '../../layout/BodyLayout';
import { Container } from '../../layout/ContainerLayout';
import { FormLayout } from '../../layout/FormLayout';
import { Row } from '../../layout/RowLayout';
import { Input } from '../../comps/Input';
import { NumberInput } from '../../comps/NumberInput';
import { CurrencyInputWrapped } from '../../comps/CurrencyInputWrapper';
import { InputFullWidthLayout } from '../../layout/input-full-width.layout';
import { Textarea } from '../../comps/TextArea';
import { Button } from '../../comps/Button';
import { List } from '../../layout/ListLayout';
import { PackageOptionListItem } from '../../comps/PackageOptionListItem';
import { TwoColumn } from "../../layout/TwoColumn";
import { getAgenciesViaOrg, loadOrgs } from "../../api/org.api";
import { Corporation } from "../../defs/corporation-model";
import { Agency } from "../../defs/agency-model";
import { InlineLoader } from "../../comps/InlineLoader";
import { useModals } from "../../hooks/modal-provider";
import { AssignClickupPackageModal } from "../../comps/AssignClickupPackageModal";


export const EditPackageView = () => {
    const [last_corp, setLastCorp] = useState<number>(null!);
    // Get the id from params
    const { id } = useParams();
    const modal = useModals();
    const navigate = useNavigate();
    const { create } = useAlerts();
    const [loading, setLoading] = useState(true);
    const [option_loading, setOptionLoading] = useState(false);
    const [error, setError] = useState('');
    const [option_error, setOptionError] = useState("");
    const [pack, setPack] = useState<Package>(null!);

    // Corp loader
    const [corp_loading, setCorpLoading] = useState(true);
    const [orgs, setOrgs] = useState<Corporation[]>([]);

    // Agency
    const [agency_loading, setAgencyLoading] = useState(true);
    const [agencies, setAgencies] = useState<Agency[]>([]);

    const update_local = (field: string, value: any) => {
        setPack({
            ...pack,
            [field]: value,
        });
    };

    // load the corporations
    const load_corporations = async () => {
        setCorpLoading(true);
        setError('');

        try {
            const result = await loadOrgs();
            setOrgs(result);
        } catch (err) {
            console.error('Failed to get the corporations: ', err);
            setError('Failed to get the corporations.');
        }

        setCorpLoading(false);
    }

    // Load the agencies based on the org selected
    const load_agencies = async (org_id: number) => {
        setAgencyLoading(true);
        setError('');

        try {
            const result = await getAgenciesViaOrg(org_id);
            setAgencies(result);
        } catch (err) {
            console.error('Failed to get the agencies: ', err);
            setError('Failed to get the agencies.');
        }

        setAgencyLoading(false);
    }

    const update = async () => {
        if (loading || !id) return;
        setLoading(true);
        setError('');

        try {
            const result = await updatePackage(Number(id), { ...pack, price: pack.price * 100 });
            load_package();
        } catch (err) {
            console.error('Failed to update the package: ', err);
            setError('Failed to update the package.');
        }
        setLoading(false);
    };

    const remove = async () => {
        setLoading(true);
        setError('');

        try {
            await removePackage(pack.id);
            navigate('/staff/package');
        } catch (err) {
            console.error('Failed to remove the package: ', err);
            setError('Failed to remove the package.');
        }

        setLoading(false);
    };

    const handle_remove = () => {
        create({
            message: 'Are you sure you want to delete this package?',
            on_confirm: () => remove(),
        });
    };

    const add_package_option = async () => {
        if (!id) return;
        setOptionLoading(true);
        setError('');

        try {
            await addPackageOption(+id, {
                name: 'New Option',
                checked: false,
                list_order: pack.package_options.length,
            })
            await load_package();

            // Scroll to bottom
            window.scrollTo(0, document.body.scrollHeight);
        } catch (err) {
            console.error('Failed to update the package: ', err);
            setOptionError('Failed to create the package option.');
        }
        setOptionLoading(false);
    }

    const update_package_option = (option?: any) => {
        setOptionLoading(true);
        setOptionError('');

        try {
            if (option) {
                update_local('options', [...pack.package_options.map((opt: any) => {
                    if (opt.id === option.id) return option;
                    return opt;
                })]);
            } else {
                load_package();
            }

        } catch (err) {
            console.error('Failed to update the package: ', err);
            setOptionError('Failed to update the package option.');
        }
        setOptionLoading(false);
    }

    const load_package = async () => {
        if (!id) return;
        setLoading(true);

        try {
            const pack = await getPackage(+id);
            setPack({ ...pack, price: pack.price / 100 });
            setLastCorp(pack.corp_id);
        } catch (err) {
            console.error('Failed to get the package: ', err);
        }

        setLoading(false);
    };

    // Handle opening the clickup modal
    const handle_clickup_modal = () => {
        modal.create({
            id: "assign_clickup_package",
            title: "Assign Clickup Package",
            content: <AssignClickupPackageModal pack={pack} onAdd={() => load_package()} />,
        });
    }

    useEffect(() => {
        if (pack?.corp_id) {
            load_agencies(pack?.corp_id);
            if (pack?.corp_id !== last_corp) update_local("agency_id", null);
            // if (agencies) update_local("agency_id", null);
        }
    }, [pack?.corp_id])

    useEffect(() => {
        if (pack) load_corporations();
    }, [pack]);

    useEffect(() => {
        if (id) {
            load_package();
        }
    }, [id]);

    if (loading) return (
        <div className="loader-con">
            <InlineLoader />
        </div>
    )

    return (
        <View>
            <ViewHeader
                title={`${!pack ? 'Loading...' : ''}${pack?.name}`}
                back
                menu={
                    <ViewHeaderMenu
                        icon={faTools}
                        label={'Actions'}
                        options={[
                            {
                                icon: faNetworkWired,
                                label: "Connect Clickup",
                                onClick: () => handle_clickup_modal()
                            },
                            {
                                label: 'Delete',
                                icon: faTrash,
                                onClick: () => handle_remove(),
                            },
                        ]}
                    />
                }
            />
            <Body>

                <Row>
                    <Container>
                        <h2>Details</h2>
                        <br />
                        <TwoColumn>
                            <Input
                                label="Name"
                                value={pack?.name}
                                onChange={(v) => update_local('name', v)}
                            />
                            <NumberInput label="List Position" value={pack?.list_order} onChange={(v) => update_local('list_order', v)} />
                            <CurrencyInputWrapped label="Budget" value={pack?.price} onChange={(v: number) => update_local("price", v)} />
                            <NumberInput label="Impressions" value={pack?.impressions} onChange={(v) => update_local('impressions', v)} />
                            <NumberInput label="Min Days" value={pack?.min_days} onChange={(v) => update_local('min_days', v)} />
                            <NumberInput label="Max Days" value={pack?.max_days} onChange={(v) => update_local('max_days', v)} />
                            <InputFullWidthLayout>
                                <Textarea
                                    label="Description"
                                    rows={4}
                                    value={pack?.desc}
                                    onChange={(v) => update_local('desc', v)}
                                />
                            </InputFullWidthLayout>
                        </TwoColumn>
                    </Container>
                </Row>

                <Row>
                    <Container>
                        <h3>Limit to Corporation and/or Agency</h3>

                        <div className="selectable-row-con">
                            <div className="selectable_list">
                                <label>Select Corporation</label>
                                <div className="selectable_list_items">

                                    {corp_loading && <div className="loader-con">
                                        <InlineLoader />
                                    </div>}

                                    {orgs?.map((corp: Corporation, i: number) => (
                                        <div className="org-list-item" id={pack?.corp_id === corp.id && "selected" || ""}
                                            onClick={() => update_local("corp_id", pack?.corp_id === corp.id ? null : corp.id)}
                                        >
                                            {corp.name}
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {pack && pack?.corp_id && agencies.length > 0 && (
                                <div className="selectable_list">
                                    <label>Select Agency</label>
                                    <div className="selectable_list_items">

                                        {agency_loading && <div className="loader-con">
                                            <InlineLoader />
                                        </div>}

                                        {agencies?.map((agency: Agency, i: number) => (
                                            <div className="org-list-item" id={pack?.agency_id === agency.id && "selected" || ""}
                                                onClick={() => update_local("agency_id", pack?.agency_id === agency.id ? null : agency.id)}
                                            >
                                                {agency.name}
                                            </div>
                                        ))}
                                    </div>
                                </div>)}
                        </div>

                        <br />
                        <div className="button-wrapper">
                            <Button
                                onClick={update}
                                loading={loading}
                            >
                                Update
                            </Button>
                        </div>
                        {error && <div className='error'>{error}</div>}

                    </Container>
                </Row>


                <Row>
                    <Container>
                        <div className="options-header">
                            <h3 className="title">Package Options</h3>
                            <div className="actions">
                                <div className="action" onClick={() => add_package_option()}>
                                    Add
                                </div>
                            </div>
                        </div>
                        <List>
                            {pack?.package_options?.sort((a: any, b: any) => a.list_order - b.list_order).map((option: any, i: number) => (
                                <PackageOptionListItem key={i} option={option} update={update_package_option} />
                            ))}
                            {/* <ListLoader loading={option_loading} />
                            <Error>{option_error}</Error> */}
                        </List>
                    </Container>
                </Row>
            </Body>
        </View>
    );
};
