import { useEffect, useState } from "react";
import { ViewHeader } from "../../comps/ViewHeader"
import { Body } from "../../layout/BodyLayout"
import { View } from "../../layout/View"
import { AmType, NewAmOrder, get_am_type_from_select, get_selected_am_type, select_am_types } from "../../defs/order-model";
import { getCustomerTeams, getCustomers } from "../../api/customer.api";
import { FormLayout } from "../../layout/FormLayout";
import { Serachbar } from "../../comps/Searchbar";
import { InlineLoader } from "../../comps/InlineLoader";
import { Customer } from "../../defs/customer-model";
import { Input } from "../../comps/Input";
import { DateInput } from "../../comps/DateInput";
import { Textarea } from "../../comps/TextArea";
import { Button } from "../../comps/Button";
import { Error } from "../../comps/Error";
import { Select } from "../../comps/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { UploadImageMiniButton } from "../../comps/UploadMiniImageButton";
import { createAmOrder, createAmOrderForStaff } from "../../api/order.api";
import { useNavigate } from "react-router-dom";

export const CreateAmOrderView = () => {
    const navigate = useNavigate();
    const [order, setOrder] = useState<NewAmOrder>({
        address: '',
        notes: '',
        date: new Date(),
        budget: 300,
        team_id: null!,
        customer_id: null!,
        media: [],
        type: AmType.ECO
    });

    const [customers, setCustomers] = useState([]);
    const [teams, setTeams] = useState([]);

    const [creating, setCreating] = useState(false);

    // Customers and teams loading
    const [customers_loading, setCustomersLoading] = useState(false);
    const [teams_loading, setTeamsLoading] = useState(false);

    // Selections
    const [selected_customer, setSelectedCustomer] = useState<number>(null!);
    const [selected_team, setSelectedTeam] = useState<number>(null!);

    const [customer_query, setCustomerQuery] = useState('');
    const [error, setError] = useState('');

    // Update the order
    const update = (key: string, value: any) => {
        setOrder({ ...order, [key]: value });
    }

    // Create the order
    const create = async () => {
        setCreating(true);

        try {
            const res = await createAmOrderForStaff({
                ...order,
                budget: order.budget * 100,
                customer_id: selected_customer,
                team_id: selected_team
            });

            navigate(`/staff/orders/am/${res.id}`);
        } catch (err) {
            setError("Failed to create the order.");
        }

        setCreating(false);
    }

    // Load the customers
    const load_customers = async (q?: string) => {
        setCustomersLoading(false);

        try {
            const res = await getCustomers(q || customer_query);
            setCustomers(res);
        } catch (err) {
            setError("Failed to load the customers.");
        }

        setCustomersLoading(false);
    }

    // Load the teams
    const load_teams = async () => {
        if (!selected_customer) return;
        setTeamsLoading(true);
        try {
            const teams = await getCustomerTeams(selected_customer);
            setTeams(teams);
        } catch (err) {
            setError("Failed to load the teams.");
        }
        setTeamsLoading(false);
    }

    // Handle adding image to the list of media
    const handle_add_media = async (url: string) => {
        const new_order = { ...order };
        new_order.media.push(url);
        setOrder(new_order);
    }

    // Remove image from the list of media
    const handle_remove_image = async (url: string) => {
        const new_order = { ...order };
        new_order.media = new_order.media.filter(u => u !== url);
        setOrder(new_order);
    }

    // Watch the selected customer
    useEffect(() => {
        load_teams();
    }, [selected_customer]);

    // Use Effect
    useEffect(() => {
        load_customers();
    }, []);

    return (
        <View>
            <ViewHeader title="Create AM Order" back />
            <Body>
                <FormLayout>
                    <div className="selectable-row-con">
                        <div className="selectable_list">
                            <label>Select Customer</label>

                            <Serachbar query={customer_query} setQuery={setCustomerQuery} onSearch={load_customers} loading={customers_loading} />

                            <div className="selectable_list_items">
                                {customers_loading && <div className="loader-con">
                                    <InlineLoader />
                                </div>}

                                {customers?.map((customer: Customer, i: number) => (
                                    <div className="customer-list-item" id={selected_customer === customer.id && "selected" || ""} onClick={() => setSelectedCustomer(customer.id)}>
                                        <div
                                            className="dp"
                                            style={{
                                                backgroundImage: `url('${customer?.dp
                                                    ? customer.dp
                                                    : `https://ui-avatars.com/api/?name=${customer?.first_name}+${customer?.last_name}&background=404e3d&color=fff&size=128`
                                                    }')`,
                                            }
                                            }
                                        />

                                        < div className="customer-name" >
                                            {customer.first_name} {customer.last_name} | {customer.agency.name}
                                        </div >
                                    </div >
                                ))}
                            </div >
                        </div >

                        {selected_customer && teams?.length > 0 && (
                            <div className="selectable_list">
                                <label>Select Team</label>
                                <div className="selectable_list_items">
                                    {teams_loading && <div className="loader-con">
                                        <InlineLoader />
                                    </div>}

                                    {teams?.map((team: any, i: number) => (
                                        <div className="customer-list-item" id={selected_team === team.team.id && "selected" || ""} onClick={() => setSelectedTeam(team.team.id)}>
                                            <div className="customer-name">
                                                {team.team.name}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                        }
                    </div>

                    <Select label="Type" options={select_am_types} value={get_selected_am_type(order?.type)} onChange={e => update("type", get_am_type_from_select(e.value))} />
                    <Input label="Address" value={order.address || ""} onChange={e => update("address", e)} />
                    <DateInput label="Start Date" value={order.date} onChange={e => update("date", e)} />
                    <Textarea label="Notes" value={order.notes || ''} onChange={e => update("notes", e)} rows={5} />

                    <div className="media-grid-wrapper">
                        <label>Included Media</label>
                        <div className="media-grid">
                            {order?.media?.map((url: string, i: number) => (
                                <div className="media-grid-item" key={i} >
                                    <img src={url} alt="" />
                                    <div className="remove-btn" onClick={() => handle_remove_image(url)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </div>
                                </div>
                            ))}

                            {/* Add another button */}
                            <UploadImageMiniButton onChange={handle_add_media} endpoint_url="/order/am/media/create-for-staff" />
                        </div>
                    </div>

                    <Button loading={creating} onClick={() => create()}>
                        Create VPA Order!
                    </Button>
                    <Error>{error}</Error>

                </FormLayout>
            </Body>
        </View >
    )
}