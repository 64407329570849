import "../styles/number-input.comp.scss"

interface Props {
    label?: string;
    type?: string;
    placeholder?: string;
    value: number;
    disabled?: boolean;
    onChange: (v: number) => void;
    onEnter?: () => void;
}

export const NumberInput = (props: Props) => {

    return (
        <div className="number-input-wrapper">
            {props.label && <label>{props.label}</label>}

            <input
                placeholder={props.placeholder || ''}
                value={props.value}
                disabled={props.disabled}
                onChange={(e: any) => props.onChange(Number(e.target.value))}
                type="number"
            />

        </div>
    )
}