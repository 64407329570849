import { useEffect, useState } from "react";
import { Agency } from "../../defs/agency-model";
import { useNavigate, useParams } from "react-router-dom";
import { fetchAgency, removeAgencies, updateAgency, updateAgencyLogo } from "../../api/agency.api";
import { View } from "../../layout/View";
import { ViewHeader } from "../../comps/ViewHeader";
import { Body } from "../../layout/BodyLayout";
import { FormLayout } from "../../layout/FormLayout";
import { UploadProfilePicture } from "../../comps/UploadProfilePicture";
import { Input } from "../../comps/Input";
import { Button } from "../../comps/Button";
import { Error } from "../../comps/Error";
import { ViewHeaderMenu } from "../../comps/ViewHeaderMenu";
import { faTools, faTrashAlt } from "@fortawesome/free-solid-svg-icons";


export const ViewAgencies = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [updating, setUpdating] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [agency, setAgency] = useState<Agency>(null!);

    // Update locally
    const update = (key: string, field: any) => {
        setAgency({ ...agency, [key]: field });
    }

    // Load the agency
    const load = async () => {
        if (!id) return;

        setLoading(true);

        try {
            const agency = await fetchAgency(+id);
            setAgency(agency);
        } catch (err) {
            setError("Failed to load the agency");
        }

        setLoading(false);
    }

    // Update the agency
    const updateRemote = async () => {
        if (!id || loading || updating) return;
        setUpdating(true);

        try {
            const res = await updateAgency(+id, agency);
            load();
        } catch (err) {
            setError("Failed to update the agency.");
        }

        setUpdating(false);
    }

    // Update the agency logo
    const updateLogo = async (e: any) => {
        if (!id) return;
        const file = e?.target?.files?.[0];
        if (!file) return;
        setUpdating(true);
        setError("");
        try {
            const form = new FormData();
            form.append("image", file);
            const res = await updateAgencyLogo(+id, form);
            update("logo", res?.logo)
        } catch (err) {
            setError("Failed to upload the image.");
        }
        setUpdating(false);
    }

    // Remove the agency
    const remove = async () => {
        if (!id) return;
        setLoading(true);
        setError("");
        try {
            const res = await removeAgencies(+id);
            navigate("/staff/agencies");
        } catch (err) {
            setError("Failed to remove the organisation. This agency probably has clients. Can't delete an agency with clients.");
        }
        setLoading(false);
    }

    useEffect(() => {
        if (id) load();
    }, [id]);


    return (
        <View>
            <ViewHeader title={"View Agency"} back
                menu={
                    <ViewHeaderMenu label="Actions" icon={faTools} options={[
                        {
                            label: "Remove Agency",
                            icon: faTrashAlt,
                            onClick: () => remove()
                        }
                    ]} />
                } />

            <Body>
                <FormLayout>
                    <UploadProfilePicture onClick={updateLogo} image={agency?.logo || ''} />

                    <Input label="Name" value={agency?.name} onChange={v => update("name", v)} />
                    <Input label="Address" value={agency?.address} onChange={v => update("address", v)} />
                    <Input label="Clickup ID" value={agency?.clickup_id || ""} onChange={v => update("clickup_id", v)} />

                    <Button onClick={updateRemote} loading={loading || updating}>
                        Update Agency
                    </Button>

                    <Error>{error} </Error>
                </FormLayout>
            </Body>
        </View>
    )

}