import "../styles/currency-input-wrapped.comp.scss";
import CurrencyInput from 'react-currency-input-field';

export interface Props {
    value: number;
    onChange: (v: number, n: string) => void;
    label?: string;
    placeholder?: string;
}

/**
 * Currency Input for entering in the Currency values
 */
export const CurrencyInputWrapped = (props: Props) => {
    return (
        <div className="input-wrapper">
            {props.label && <label>{props.label}</label>}
            <CurrencyInput prefix="$" value={props.value} onValueChange={props.onChange as any} placeholder={props.placeholder || "Enter amount..."} />
        </div>
    )
}