import { useState } from "react";
import { createOrg } from "../../api/org.api";
import { useNavigate } from "react-router-dom";
import { View } from "../../layout/View";
import { ViewHeader } from "../../comps/ViewHeader";
import { Body } from "../../layout/BodyLayout";
import { FormLayout } from "../../layout/FormLayout";
import { Input } from "../../comps/Input";
import { Button } from "../../comps/Button";
import { Error } from "../../comps/Error";

export const CreateOrgView = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [error, setError] = useState("");

    const create = async () => {
        setLoading(true);
        setError("");

        try {
            const res = await createOrg(name);
            navigate("/staff/org");
        } catch (err) {
            setError("Failed to create the organization.");
        }

        setLoading(false);
    }

    return (
        <View>
            <ViewHeader title="Create Organisation" back />

            <Body>
                <FormLayout>
                    <Input label="Name" value={name} onChange={setName} />
                    <br />
                    <Button onClick={create} loading={loading}>
                        Create!
                    </Button>

                    <Error>{error}</Error>
                </FormLayout>
            </Body>
        </View>
    )

}