import { useNavigate, useParams } from "react-router-dom"
import { ViewHeader } from "../../comps/ViewHeader"
import { Body } from "../../layout/BodyLayout"
import { FormLayout } from "../../layout/FormLayout"
import { View } from "../../layout/View"
import { Input } from "../../comps/Input"
import { useEffect, useState } from "react"
import { Corporation } from "../../defs/corporation-model"
import { getOrg, removeOrg, updateOrg, updateOrgLogo } from "../../api/org.api"
import { UploadProfilePicture } from "../../comps/UploadProfilePicture"
import { Button } from "../../comps/Button"
import { ViewHeaderMenu } from "../../comps/ViewHeaderMenu"
import { faTools, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { Error } from "../../comps/Error"


export const ViewOrganisation = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [org, setOrg] = useState<Corporation>(null!);
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [updating, setUpdating] = useState<boolean>(false);

    // Update locally
    const update = (key: string, field: any) => {
        setOrg({ ...org, [key]: field });
    }

    // Load the Corporation
    const load = async () => {
        if (!id) return;
        setLoading(true);
        try {
            const temp = await getOrg(id);
            setOrg(temp);
        } catch (err) {
            setError("Failed to load the organisation.");
        }
        setLoading(false);
    }

    // Update the logo
    const updateLogo = async (e: any) => {
        if (!id) return;
        const file = e?.target?.files?.[0];
        if (!file) return;
        setUpdating(true);
        setError("");
        try {
            const form = new FormData();
            form.append("image", file);
            const res = await updateOrgLogo(+id, form);
            update("logo", res?.logo)
        } catch (err) {
            setError("Failed to upload the image.");
        }
        setUpdating(false);
    }

    // Update the remote
    const updateRemote = async () => {
        if (!id) return;
        setLoading(true);
        setError("");
        try {
            const res = await updateOrg(id, {
                name: org.name,
                address: org.address || ""
            });
            load();
        } catch (err) {
            setError("Failed to update the organisation.");
        }
        setLoading(false);
    }

    // Remove an org
    const remove = async () => {
        if (!id || loading || updating) return;
        setLoading(true);
        setError("");
        try {
            await removeOrg(id);
            navigate("/staff/org");
        } catch (err) {
            setError("Failed to remove the organisation.");
        }
        setLoading(false);
    }

    useEffect(() => {
        if (id) load();
    }, [id])

    return (
        <View>
            <ViewHeader title="View Organisation" back
                menu={
                    <ViewHeaderMenu label="Actions" icon={faTools} options={[
                        {
                            label: "Remove Organisation",
                            icon: faTrashAlt,
                            onClick: () => remove()
                        }
                    ]} />
                } />


            <Body>
                <FormLayout>
                    <UploadProfilePicture onClick={updateLogo} image={org?.logo || ''} />

                    <Input label="Name" value={org?.name} onChange={v => update("name", v)} />
                    <Input label="Address" value={org?.address} onChange={v => update("address", v)} />

                    <Button onClick={updateRemote}>Update</Button>

                    <Error>{error}</Error>

                </FormLayout>
            </Body>
        </View>
    )

}