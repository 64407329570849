import { useEffect, useState } from "react"
import { Serachbar } from "../../comps/Searchbar"
import { ViewHeader } from "../../comps/ViewHeader"
import { Body } from "../../layout/BodyLayout"
import { View } from "../../layout/View"
import { Order } from "../../defs/order-model"
import { getOrders } from "../../api/order.api"
import { List } from "../../layout/ListLayout"
import { OrderListItem } from "../../comps/OrderListItem"
import { ViewHeaderMenu } from "../../comps/ViewHeaderMenu"
import { faPlusCircle, faTools, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"


export const ViewAllOrders = () => {
    const navigate = useNavigate();
    const [query, setQuery] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>("")
    const [orders, setOrders] = useState<Order[]>([]);

    // Load all the orders
    const load = async () => {
        setLoading(true)
        setError("");

        try {
            const orders = await getOrders(query)
            setOrders(orders)
        } catch (err) {
            setError("Failed to load orders")
        }

        setLoading(false)
    }

    useEffect(() => {
        load()
    }, [query])


    return (
        <View>
            <ViewHeader title="View Orders" back menu={
                <ViewHeaderMenu label="Actions" icon={faTools} options={[
                    {
                        label: "Create VPA Campaign",
                        icon: faPlusCircle,
                        onClick: () => navigate("/staff/orders/create-vpa")
                    },
                    {
                        label: "Create AM Campaign",
                        icon: faPlusCircle,
                        onClick: () => navigate("/staff/orders/create-am")
                    }
                ]} />
            } />


            <Body>
                <Serachbar query={query} setQuery={setQuery} onSearch={load} loading={loading} />

                <List>
                    {orders?.map((order, index) => (
                        <OrderListItem key={index} order={order} />
                    ))}
                </List>
            </Body>
        </View>
    )
}