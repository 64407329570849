import { client } from "./api.config"

export const fetchAllAgencies = async () => {
    const res = await client().get('/agency');
    return res.data;
}

// Get an agency via it's ID
export const fetchAgency = async (id: number) => {
    const res = await client().get(`/agency/${id}`);
    return res.data;
}

// Update an agency
export const updateAgency = async (id: number, data: any) => {
    data = {
        name: data.name,
        address: data.address,
        clickup_id: data.clickup_id || '',
    };

    const res = await client().put(`/agency/${id}`, data);
    return res.data;
}

// Update an agency's logo
export const updateAgencyLogo = async (id: number, data: any) => {
    const res = await client().post(`/agency/${id}/logo`, data);
    return res.data;
}

// Create a new agency
export const addAgency = async (data: { corporation_id: number, name: string, address: string }) => {
    const res = await client().post(`/agency`, data);
    return res.data;
}

// Get all the customers within the agency
export const fetchAgencyCustomers = async (agency_id: number) => {
    const res = await client().get(`/agency/${agency_id}/customer`);
    return res.data;
}

// Query the agencies
export const queryAgencies = async (query: string) => {
    const res = await client().get("/agencies", { params: { query } });
    return res.data;
}

// Remove the agencies
export const removeAgencies = async (id: number | string) => {
    const res = await client().delete(`/agency/${id}`);
    return res.data;
}