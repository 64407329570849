import "../../styles/create-vpa-order.scss";
import { useEffect, useState } from "react"
import { ViewHeader } from "../../comps/ViewHeader"
import { View } from "../../layout/View"
import { NewVpaOrder, Package } from "../../defs/order-model"
import { getAgencyCustomers, getCustomerTeams, getCustomers, getPackagesForCustomer } from "../../api/customer.api"
import { Body } from "../../layout/BodyLayout"
import { FormLayout } from "../../layout/FormLayout"
import { Input } from "../../comps/Input"
import { Textarea } from "../../comps/TextArea"
import { DateInput } from "../../comps/DateInput"
import { InlineLoader } from "../../comps/InlineLoader"
import { add_commas } from "../../utils/numbers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye } from "@fortawesome/free-solid-svg-icons"
import { Serachbar } from "../../comps/Searchbar"
import { Customer } from "../../defs/customer-model"
import { Button } from "../../comps/Button";
import { createVpaOrder } from "../../api/order.api";
import { useNavigate } from "react-router-dom";
import { Error } from "../../comps/Error";

export const CreateVpaOrder = () => {
    const navigate = useNavigate();
    const [order, setOrder] = useState<NewVpaOrder>({
        address: '',
        notes: '',
        start_date: new Date(),
        end_date: new Date(),
        package_id: null
    });

    // Setup loaders for each request
    const [packages, setPackages] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [teams, setTeams] = useState([]);

    const [packages_loading, setPackagesLoading] = useState(true);
    const [customers_loading, setCustomersLoading] = useState(false);
    const [teams_loading, setTeamsLoading] = useState(false);

    const [creating, setCreating] = useState(false);
    const [error, setError] = useState('');

    const [customer_query, setCustomerQuery] = useState('');

    // Selections
    const [selected_package, setSelectedPackage] = useState<number>(null!);
    const [selected_customer, setSelectedCustomer] = useState<number>(null!);
    const [selected_team, setSelectedTeam] = useState<number>(null!);

    // Create the order
    const create = async () => {
        setCreating(true);

        try {
            const res = await createVpaOrder({
                ...order,
                customer_id: selected_customer,
                team_id: selected_team,
                package_id: selected_package
            });
            navigate(`/staff/orders/vpa/${res.id}`);
        } catch (err) {
            setError("Failed to create the order.");
        }

        setCreating(false);
    }

    // Update locally
    const update = (key: string, value: any) => {
        setOrder({ ...order, [key]: value });
    }

    // Load the packages
    const load_packages = async () => {
        if (!selected_customer) return;
        setPackagesLoading(true);

        try {
            const res = await getPackagesForCustomer(selected_customer);
            setPackages(res);
        } catch (err) {
            setError("Failed to load the packages.");
        }
        setPackagesLoading(false);
    }

    // Load the customers
    const load_customers = async (q?: string) => {
        setCustomersLoading(false);

        try {
            const res = await getCustomers(q || customer_query);
            setCustomers(res);
        } catch (err) {
            setError("Failed to load the customers.");
        }

        setCustomersLoading(false);
    }

    // Load the teams -> Load teams if customer selected is apart of multiple teams, if there are no teams it will auto create one
    const load_teams = async () => {
        if (!selected_customer) return;
        setTeamsLoading(true);
        try {
            const teams = await getCustomerTeams(selected_customer);
            setTeams(teams);
        } catch (err) {
            setError("Failed to load the teams.");
        }
        setTeamsLoading(false);
    }

    useEffect(() => {
        if (selected_customer) {
            setSelectedTeam(null!);
            setSelectedPackage(null!);
            load_teams();
            load_packages();
        }
    }, [selected_customer]);

    useEffect(() => {
        load_customers();
    }, [])

    console.log("GOt the selected id: ", selected_team);


    return (
        <View>
            <ViewHeader title="Create VPA Order" />
            <Body>

                <FormLayout>
                    <div className="selectable-row-con">
                        <div className="selectable_list">
                            <label>Select Customer</label>

                            <Serachbar query={customer_query} setQuery={setCustomerQuery} onSearch={load_customers} loading={customers_loading} />

                            <div className="selectable_list_items">
                                {customers_loading && <div className="loader-con">
                                    <InlineLoader />
                                </div>}

                                {customers?.map((customer: Customer, i: number) => (
                                    <div className="customer-list-item" id={selected_customer === customer.id && "selected" || ""} onClick={() => setSelectedCustomer(customer.id)}>
                                        <div
                                            className="dp"
                                            style={{
                                                backgroundImage: `url('${customer?.dp
                                                    ? customer.dp
                                                    : `https://ui-avatars.com/api/?name=${customer?.first_name}+${customer?.last_name}&background=404e3d&color=fff&size=128`
                                                    }')`,
                                            }
                                            }
                                        />

                                        < div className="customer-name" >
                                            {customer.first_name} {customer.last_name} | {customer.agency.name}
                                        </div >
                                    </div >
                                ))}
                            </div >
                        </div >

                        {selected_customer && teams?.length > 0 && (
                            <div className="selectable_list">
                                <label>Select Team</label>
                                <div className="selectable_list_items">
                                    {teams_loading && <div className="loader-con">
                                        <InlineLoader />
                                    </div>}

                                    {teams?.map((team: any, i: number) => (
                                        <div className="customer-list-item" id={selected_team === team.team.id && "selected" || ""} onClick={() => setSelectedTeam(team.team.id)}>
                                            <div className="customer-name">
                                                {team.team.name}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                        }
                    </div >

                    {/* Select Pacakge */}
                    {
                        selected_customer && (<div className="selectable_list">
                            <label>Select Pacakge</label>

                            <div className="selectable_list_items">
                                {packages_loading && <div className="loader-con">
                                    <InlineLoader />
                                </div>}

                                {packages?.map((pack: Package, i: number) => (
                                    <div className="package-list-item" onClick={() => setSelectedPackage(pack.id)} id={selected_package === pack.id && "selected" || ""}>
                                        <div className="package-header">
                                            <div className="package-name">
                                                {pack.name}
                                            </div>
                                            <div className="package-details">
                                                <FontAwesomeIcon icon={faEye} />
                                                {add_commas(pack.impressions)}
                                            </div>
                                        </div>
                                        <div className="package-price">
                                            ${pack.price / 100}
                                        </div>

                                    </div>
                                ))}
                            </div>
                        </div>)
                    }

                    <Input label="Address" value={order.address} onChange={e => update("address", e)} />
                    <DateInput label="Start Date" value={order.start_date} onChange={e => update("start_date", e)} />
                    <DateInput label="End Date" value={order.end_date} onChange={e => update("end_date", e)} />
                    <Textarea label="Notes" value={order.notes} onChange={e => update("notes", e)} rows={5} />

                    <Button loading={creating} onClick={() => create()}>
                        Create VPA Order!
                    </Button>
                    <Error>{error}</Error>
                </FormLayout >
            </Body >
        </View >
    );
}