import "../../styles/view-all-orgs-view.scss";
import { useEffect, useState } from "react"
import { Corporation } from "../../defs/corporation-model"
import { View } from "../../layout/View"
import { ViewHeader } from "../../comps/ViewHeader"
import { Serachbar } from "../../comps/Searchbar"
import { Body } from "../../layout/BodyLayout"
import { List } from "../../layout/ListLayout"
import { queryOrgs } from "../../api/org.api"
import { ViewHeaderMenu } from "../../comps/ViewHeaderMenu";
import { faCirclePlus, faTools } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export const ViewAllOrganisations = () => {
    const navigate = useNavigate();
    const [query, setQuery] = useState<string>("")
    const [orgs, setOrgs] = useState<Corporation[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>("")

    // Load the query
    const load = async (q?: string) => {
        setLoading(true);

        try {
            const orgs = await queryOrgs(q || query);
            setOrgs(orgs);
        } catch (err) {
            setError("Failed to load the orgs")
        }

        setLoading(false);
    }

    useEffect(() => {
        load();
    }, [])

    return (
        <View>
            <ViewHeader title="View Organisations" back
                menu={
                    <ViewHeaderMenu label="Actions" icon={faTools} options={[
                        {
                            label: "Create Organisation",
                            icon: faCirclePlus,
                            onClick: () => navigate("/staff/org/create")
                        }
                    ]} />
                }
            />


            <Body>
                <Serachbar query={query} setQuery={setQuery} onSearch={load} loading={loading} />

                <List>
                    {orgs?.map((org, index) => (
                        <div className="org-list-item" onClick={() => navigate(`/staff/org/${org.id}`)}>
                            {/* Company Logo */}
                            <div className="company-logo">
                                <img src={org.logo} alt="" />
                            </div>

                            {/* Company details */}
                            <div className="company-details">
                                <h3>{org.name}</h3>
                                {/* <p>{org.adress}</p> */}
                            </div>

                            {/* Inline stats */}
                            <div className="stats">
                                <div className="stat">
                                    <label>Agencies Count:</label>
                                    <div className="value">{org?._count?.agencies || "N/A"}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </List>
            </Body>
        </View>
    )

}
